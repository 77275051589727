import {combineReducers} from 'redux';
import Customizer from './customizer.reducer';
import Menu from './menu.reducer';
import Components from './components.reducer';

const reducers = combineReducers({
    Customizer,
    Menu,
    Components
});

export default reducers;
