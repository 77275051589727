import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import WidgetCardHeader from "../helpers/widgetCardHeader";
import Api from "../../../../service/api";


class FPNServicesWidget extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formData.id !== this.props.formData.id) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const {formData} = this.props;
        fetch(Api.getBackendAddress() + 'app/apps/fpn/projectSummary/' + formData.id).then(res => res.json()).then(res => {
            if (res.success === 1) {
                this.setState({
                    data: res.services
                })
            }
        });
    };

    convertToInvoice = () => {
        const {formData, history} = this.props;
        fetch(Api.getBackendAddress() + 'app/apps/fpn/convertProjectToInvoice/' + formData.id).then(res => res.json()).then(res => {
            if (res.success === 1) {
                history.replace(`${process.env.PUBLIC_URL}/components/finances/invoiceoutgoing/update/${res.id}`)
            }
        });
    }

    render() {
        const {data} = this.state;

        return (
            <Fragment>

                <div className={"card"}>
                    <WidgetCardHeader title={"Summary"}/>
                    <div className={"card-body"}>
                        <table className={"table table-striped no-table-th-border"}>
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Service</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Invoiced</th>
                                <th scope="col">Remaining</th>
                                <th scope="col">Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item, index) => {
                                return (
                                    <tr key={"service-row-" + item.service.id + "-" + index}>
                                        <th>{index + 1}</th>
                                        <td>{item.name}</td>
                                        <td>{item.sale}</td>
                                        <td>{item.invoice}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.price}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className={"card-footer"}>
                        <button onClick={() => this.convertToInvoice()}
                                className={"btn btn-primary btn-pill btn-air-primary btn-sm"}>Convert to invoice
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(FPNServicesWidget);
