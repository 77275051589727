import React from 'react';
import ReactLoading from "react-loading";

class LoadingComponent extends React.Component {

    render() {
        return (
            <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(44, 172, 237, 0.04)', borderRadius: 10}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <ReactLoading type={"bubbles"} color={"#d5effb"} height={20} width={100}/>
                </div>
            </div>
        );
    }
}

export default LoadingComponent;
