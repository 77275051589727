import React, {Component, Fragment} from "react";

import ComponentTitle from "../componentTitle";
import Api from "../../../service/api";
import {Auth0Context} from "../../../react-auth0-spa";
import {GoogleApiWrapper, InfoWindow, Map, Marker} from 'google-maps-react';


class FpnMap extends Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columns: [],
            openId: -1,
            activeMarker: null,
            infoData: null,
            selectedValues: null
        };
    }

    onSelect = (key, value) => {
        let selectedValues = {...this.state.selectedValues};
        selectedValues[key] = value;
        this.setState({
            selectedValues: selectedValues
        });
    }

    fetchData = (onlyExternal) => {
        const pageSize = 10000;
        const page = 1;
        const parentName = 'fpn';
        const componentName = 'fpn_events';
        const extraQuery = {};
        const {user} = this.context;
        const addColumns = ['shortName', 'location'];
        const {selectedValues} = this.state;

        Api.getTableData(parentName, componentName, extraQuery, null, page, pageSize, '', user, null, 0, addColumns).then(res => {
            let newData = {data: res.data, columns: res.columns}
            if (!selectedValues) {
                let statusColumn = res.columns.filter(item => item.key === 'status');
                if (!statusColumn) {
                    return null;
                }
                statusColumn = statusColumn[0];
                let newValues = {};
                statusColumn.values.map(item => {
                    newValues[item.key] = true;
                });
                newData.selectedValues = newValues;
            }
            this.setState(newData);
        })
    };

    /**
     * adding dragable properties to external events through javascript
     */
    componentDidMount() {

        this.fetchData();
    }

    onInfoWindowClose = () => {
        this.setState({
            openId: -1,
            activeMarker: null,
            infoData: null
        });
    }

    getStatusColumn = () => {
        let statusColumn = this.state.columns.filter(item => item.key === 'status');
        if (!statusColumn) {
            return null;
        }
        return statusColumn[0];
    }

    getStatusComponent = (status) => {
        let statusColumn = this.getStatusColumn();
        if (!statusColumn) {
            return '';
        }

        let selectedVal = statusColumn.values.filter(item => item.key === status);
        if (!selectedVal) {
            return '';
        }
        selectedVal = selectedVal[0];
        return (
            <span className={"badge badge-pill badge-" + selectedVal.color}>{selectedVal.title}</span>
        );

    }

    render() {

        const {history, google} = this.props;
        const {data, openId, activeMarker, infoData, selectedValues} = this.state;
        const statusColumn = this.getStatusColumn();


        const filteredData = data.filter(item => selectedValues[item.status]);

        const initialCenter = data.length > 0 ? data[0]._raw_location : {lat: 0, lng: 0};
        return (
            <Fragment>
                <ComponentTitle component={{title: 'Map'}} history={history}/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Events planning ({filteredData.length})</h5>
                                    {statusColumn &&
                                    <div className={"row mt-2"}>
                                        {statusColumn.values.map(item => {
                                            return (
                                                <div className={"col-2"}>
                                                    <div className={"checkbox checkbox-solid-" + item.color}>
                                                        <input id={"solid" + item.key} type="checkbox"
                                                               defaultChecked={selectedValues[item.key]}
                                                               onChange={() => this.onSelect(item.key, !selectedValues[item.key])}/>
                                                        <label htmlFor={"solid" + item.key}>{item.title}</label>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    }
                                </div>
                                <div className="card-body" style={{minHeight: 550, position: 'relative'}}>
                                    {filteredData.length > 0 &&
                                    <Map google={google} zoom={8} initialCenter={initialCenter}
                                         style={{width: '95%', minHeight: 500, position: 'relative'}}
                                    >
                                        {filteredData.map(item => {
                                            return (
                                                <Marker
                                                    key={"marker-" + item.id}
                                                    id={item.id}
                                                    position={item._raw_location}
                                                    onClick={(props, marker) => this.setState({
                                                        activeMarker: marker,
                                                        openId: item.id,
                                                        infoData: item
                                                    })}
                                                >
                                                </Marker>
                                            )
                                        })}
                                        <InfoWindow
                                            marker={activeMarker}
                                            onClose={this.onInfoWindowClose}
                                            visible={openId > 0}>
                                            <div>
                                                {infoData &&
                                                <div>
                                                    <h5>{infoData._raw_shortName} {this.getStatusComponent(infoData.status)}</h5>
                                                    <a href={"/components/fpn/fpn_events/view/" + infoData.id}
                                                       target={"_blank"}>Open in new window</a>
                                                </div>
                                                }
                                            </div>
                                        </InfoWindow>
                                    </Map>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>


        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyBABF_V9G5uKXP25lO82fHaVoG7jEIsjsQ")
})(FpnMap)
