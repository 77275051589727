import React from 'react';
const nl2br = require('react-nl2br');

const AjRowWrapper = (props) => {
    const {field, children, index} = props;

    const fieldId = "field" + field.key;

    let labelSize = 'col-4';
    let inputSize = 'col-8';

    let required = field.required;

    if (field.afterFieldText) {
        const inputSizeCol = 8 - field.afterFieldSize;
        inputSize = 'col-' + inputSizeCol;
    }

    if (field.type === 'one-to-many') {
        labelSize = 'col-12';
        inputSize = 'col-12';
    }

    if (field.type === 'section') {
        labelSize = 'col-12';
    }

    const showColon = field.type !== 'section' && field.type !== 'one-to-many';

    let extraClass = [];
    if (field.type === 'section' || field.type === 'one-to-many') {
        extraClass.push("f-w-600");
        if (index !== 0) {
            extraClass.push("mt-4");
        }
    }

    return (
        <div className="form-group row" key={'index' + field.key}>
            <label className={"col-form-label pt-0 " + labelSize + " " + extraClass.join(" ")}
                   htmlFor={fieldId}>{field.title}{required &&
            <span className={"txt-danger"}>*</span>}{showColon ? ':' : ''}
            </label>

            {field.type !== 'section' && <div className={inputSize}>
                {children}
            </div>}


            {!!field.afterFieldText &&
            <div className={'col-' + field.afterFieldSize}>
                <small className="form-text text-muted">{nl2br(field.afterFieldText)}</small>
            </div>
            }
            {field.description &&
            <small className="form-text text-muted col-12">{nl2br(field.description)}</small>}
        </div>
    );
};

export default AjRowWrapper;
