import React from 'react';

import moment from "moment";

const DateTimeColumn = (props) => {
    const {column, val, isView} = props;
    return (moment(val).format('YYYY-MM-DD HH:mm'));
};

export default DateTimeColumn;
