import React from 'react';
import AjRowWrapper from "./ajrowwrapper";
import AjFormRowContent from "./ajformrowcontent";


class AjFormRow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {field, onDataUpdate, formData, index} = this.props;
        const fieldId = "field" + field.key;

        const inputPlace = <AjFormRowContent field={field} onDataUpdate={onDataUpdate} formData={formData}/>;

        return <AjRowWrapper key={'row-' + fieldId} field={field} index={index}>{inputPlace}</AjRowWrapper>;
    }

};

export default AjFormRow;
