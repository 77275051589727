import React, {Fragment, useState} from 'react';
import {useAuth0} from "../../../react-auth0-spa";
import Api from "../../../service/api";
import {toast} from "react-toastify";

const ChangePasswordPopup = ({toggle} = this.props) => {
    const {user} = useAuth0();
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const sendData = () => {
        if (password && passwordRepeat && password === passwordRepeat) {
            let formData = new FormData();
            formData.append('password', password);
            formData.append('userId', user.sub);
            fetch(Api.getBackendAddress() + 'app/apps/auth0/changePassword', {
                method: 'POST',
                body: formData,
            }).then(res => res.json()).then(res => {
                if (!res.error) {
                    toggle();
                } else {
                    toast.error(res.error, {position: toast.POSITION.BOTTOM_RIGHT});
                }
            });
        } else {
            toast.error('Password empty or don\t match', {position: toast.POSITION.BOTTOM_RIGHT});
        }
    };

    return (
        <Fragment>
            <form className="theme-form">
                <h5 className="f-16 mb-3 f-w-600">CREATE YOUR PASSWORD</h5>
                <div className="form-group">
                    <label className="col-form-label">New Password</label>
                    <input className="form-control" value={password} type="password" placeholder="*****"
                           onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label className="col-form-label">Retype Password</label>
                    <input className="form-control" value={passwordRepeat} type="password" placeholder="*****"
                           onChange={(e) => setPasswordRepeat(e.target.value)}/>
                </div>
                <div className="form-group form-row mb-0">
                    <div className="col-md-2">
                        <button className="btn btn-primary" type="button" onClick={sendData}>Done</button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};


export default ChangePasswordPopup;
