import Api from "../../../service/api";

class DefaultCompanyValuesDataManager {
    doJobOnLoad() {

    }

    doJob(formData, value, key, childIndex, childKey, extraData, user) {
        return new Promise((resolve, reject) => {

            Api.getViewData('crm', 'companies', value.id, user).then(res => {
                if (res.success === 1) {
                    let dataToUpdate = [];
                    if ('vat' in formData) {
                        dataToUpdate.push({val: res.formData.defaultVat, key: 'vat'});
                    }
                    if ('currency' in formData) {
                        dataToUpdate.push({val: res.formData.defaultCurrency, key: 'currency'});
                    }
                    if ('fpnPriceList' in formData) {
                        dataToUpdate.push({val: res.formData.defaultFpnPriceList, key: 'fpnPriceList'});
                    }
                    // console.log('dataToUpdate', dataToUpdate);
                    resolve({formData, dataToUpdate});
                }
            });
        });
    }
}

export default DefaultCompanyValuesDataManager;
