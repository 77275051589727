import React from 'react';
import AjFormRow from "./ajformrow";
import AjViewRow from "./ajviewrow";

const AjForm = (props) => {
    const {fields, onDataUpdate, formData, position} = props;

    return (
        <form className="theme-form">
            {fields.filter((item) => item.editPosition === position && item.type !== 'skip').map((item, index) => {
                const key = "form-row-" + item.key;
                if (item.readonly) {
                    return <AjViewRow key={key} field={item} formData={formData}/>
                }
                return <AjFormRow key={key} field={item} onDataUpdate={onDataUpdate} formData={formData} index={index}/>
            })}
        </form>
    );
};

export default AjForm;
