class InvoiceLineDataManager {

    doJob(formData, value, key, childIndex, childKey) {
        console.log('InvoiceLineDataManager', value, key);
        return new Promise((resolve, reject) => {
            this.formData = formData;
            this.value = value;
            this.key = key;
            this.childIndex = childIndex;
            this.childKey = childKey;

            this.calculatePrices();
            this.calculateTotals();

            this.calculateElementTotals();

            resolve({formData: this.formData});
        });
    }

    calculatePrices() {
        let vat = this.formData['vat'] ? parseInt(this.formData['vat'].name, 10) : 0;
        let element = this.formData[this.key][this.childIndex];

        element.priceWDiscount = element.price * (1 - (element.discount / 100));

        element.priceWVat = element.priceWDiscount * (1 + (vat / 100));

        this.formData[this.key][this.childIndex] = element;
    }

    calculateTotals() {
        let element = this.formData[this.key][this.childIndex];

        element.total = element.quantity * element.price;
        element.totalWDiscount = element.quantity * element.priceWDiscount;
        element.totalWVat = element.quantity * element.priceWVat;


        this.formData[this.key][this.childIndex] = element;
    }

    calculateElementTotals() {
        let total = 0;
        let totalWDiscount = 0;
        let totalWVat = 0;
        this.formData[this.key].map((item) => {
            total += item.total;
            totalWDiscount += item.totalWDiscount;
            totalWVat += item.totalWVat;

        });
        this.formData.total = total;
        this.formData.totalWDiscount = totalWDiscount;
        this.formData.totalWVat = totalWVat;

        this.formData.totalDiscount = this.formData.totalWDiscount - this.formData.total;
        this.formData.totalVat = this.formData.totalWVat - this.formData.totalWDiscount;
    }

}

export default InvoiceLineDataManager;
