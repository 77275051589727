import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Api from "../../../service/api";

class TodoWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            addTask: '',
            markAll: false,
            task: ''
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const {extraData} = this.props;

        fetch(Api.getBackendAddress() + 'app/tasks/get-tasks/' + extraData.parentTag).then(res => res.json()).then(res => {
            this.setState({data: res.data});
        });
    }

    createTask = () => {
        const {extraData} = this.props;

        const formData = new FormData();
        formData.append("parentTag", extraData.parentTag);
        formData.append("description", this.state.task);

        fetch(Api.getBackendAddress() + 'app/tasks/create', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                this.fetchData();
            })
            .catch(error => {
                console.error(error)
            })
    };

    deleteTask = (id) => {
        const {extraData} = this.props;

        const formData = new FormData();
        formData.append("parentTag", extraData.parentTag);
        formData.append("id", id);

        fetch(Api.getBackendAddress() + 'app/tasks/delete', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                this.fetchData();
            })
            .catch(error => {
                console.error(error)
            })
    };

    markCompletedTask = (id) => {
        const {extraData} = this.props;

        const formData = new FormData();
        formData.append("parentTag", extraData.parentTag);
        formData.append("id", id);

        fetch(Api.getBackendAddress() + 'app/tasks/markCompleted', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                this.fetchData();
            })
            .catch(error => {
                console.error(error)
            })
    };

    render() {
        const {data, addTask, markAll, task} = this.state;


        const handleMarkedTodo = (itemId, itemStatus) => {

        };

        const markAllStatus = () => {

        };

        const openTaskWrapper = () => {
            this.setState({
                addTask: 'visible'
            });
        };

        const closeTaskWrapper = () => {
            this.setState({
                addTask: ''
            });
        };

        const onTaskChanged = (e) => {
            this.setState({task: e.target.value});
        };


        return (
            <Fragment>

                <div className="card">
                    <div className={"card-header pb-2 pt-4"}>
                        <h6>TO-DO</h6>
                    </div>
                    <div className="card-body">
                        <div className="todo">
                            <div className="todo-list-wrapper">
                                <div className="todo-list-container">

                                    <div className="todo-list-body">
                                        <ul id="todo-list">
                                            {data.length > 0 ?
                                                data.map((todo, index) =>
                                                    <li className={"task " + todo.status} key={index}>
                                                        <div className="task-container">
                                                            <h4 className="task-label">{todo.description}</h4>
                                                            <span className="task-action-btn">
                                                                        <span className="action-box large delete-btn"
                                                                              title="Delete Task"
                                                                              onClick={() => this.deleteTask(todo.id)}>
                                                                            <i className="icon"><i
                                                                                className="icon-trash"></i></i></span>
                                                                        <span className="action-box large complete-btn"
                                                                              title="Mark Complete"
                                                                              onClick={() => this.markCompletedTask(todo.id)}
                                                                        >
                                                                            <i className="icon"><i
                                                                                className="icon-check"></i></i>
                                                                        </span>
                                                                    </span>
                                                        </div>
                                                    </li>
                                                ) : ''}
                                        </ul>
                                    </div>
                                    <div className="todo-list-footer">
                                        <div className="add-task-btn-wrapper"><span className="add-task-btn">
                                                    <button className="btn btn-primary" onClick={openTaskWrapper}>
                                                        <i className="icon-plus"></i> Add new task</button></span></div>
                                        <div className={"new-task-wrapper " + addTask}>
                                                    <textarea
                                                        className={"ng-untouched ng-pristine ng-valid"}
                                                        id="newtask"
                                                        placeholder="Enter new task here. . ."
                                                        defaultValue={task}
                                                        onChange={onTaskChanged}
                                                    >
                                                    </textarea>
                                            <span className="btn btn-danger cancel-btn" id="close-task-panel"
                                                  onClick={closeTaskWrapper}>Close</span>
                                            <span className="btn btn-success ml-3 add-new-task-btn" id="add-task"
                                                  onClick={this.createTask}>Add Task</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="notification-popup hide">
                                <p><span className="task"></span><span className="notification-text"></span></p>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(TodoWidget);
