import React from 'react';
import DateColumn from "./date";
import ManyToOneColumn from "./manytoone";
import AjEnumColumn from "./enum";
import AjFormRowContent from "../../../form/ajformrowcontent"
import DateTimeColumn from "./datetime";
import AjStatusColumn from "./status";
import AddressColumn from "./AddressColumn";
import NumberFormat from 'react-number-format';
import EditorColumn from "./EditorColumn";

const nl2br = require('react-nl2br');


const Column = (props) => {
    const {column, val, isView, isForm, rowData, onDataUpdate, groupName, componentName, id, editable} = props;

    let columnData = val;
    if (column.field && !isView) {
        columnData =
            <AjFormRowContent field={column.field} formData={rowData} onDataUpdate={onDataUpdate}/>
        ;
    } else if (column.type === 'date') {
        columnData = <DateColumn column={column} val={val} isView={isView}/>
    } else if (column.type === 'date_time') {
        columnData = <DateTimeColumn column={column} val={val} isView={isView}/>
    } else if (column.type === 'many-to-one') {
        columnData = <ManyToOneColumn column={column} val={val} isView={isView}/>
    } else if (column.type === 'enum') {
        columnData = <AjEnumColumn column={column} val={val} isView={isView}/>
    } else if (column.type === 'status') {
        columnData = <AjStatusColumn column={column} val={val} isView={isView} isForm={isForm} groupName={groupName}
                                     componentName={componentName}
                                     editable={editable}
                                     id={id}/>
    } else if (column.type === 'column-set') {
        let validColumns = column.columns.filter((item) => {
            let isOk = false;
            eval('isOk = ' + item.condition);

            return isOk;
        });

        if (validColumns.length > 0) {
            columnData = [];
            validColumns.map((item, index) => {
                let columnSet = item.column;
                if (index) {
                    columnData.push(<br key={"line-" + id + "-br-" + index}/>)
                }
                columnData.push(<Column key={"line-" + id + "-col-" + index}
                                        rowData={rowData}
                                        column={columnSet}
                                        val={rowData[columnSet.key]}
                                        onDataUpdate={onDataUpdate}
                                        isForm={isForm}
                                        isView={isView}
                />);
            })

        }
    } else if (column.type === 'file') {
        columnData = val ? val.name : '-';
    } else if (column.type === 'bool') {
        columnData = val.name;
    } else if (column.type === 'text') {
        columnData = nl2br(val);
    } else if (column.type === 'address') {
        columnData = <AddressColumn column={column} val={val} isView={isView}/>;
    } else if (column.type === 'float') {
        columnData =
            <NumberFormat value={val} displayType={'text'} thousandSeparator={','} allowNegative decimalScale={2}
                          fixedDecimalScale/>
    } else if (column.type === 'ckeditor') {
        columnData = <EditorColumn column={column} val={val} isView={isView}/>;
    }

    return columnData;
};

export default Column;
