import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import * as Icon from 'react-feather'

class AddressField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResult: '',
            showSearch: false
        }
    }

    showSearch = (showSearch) => {
        this.setState({showSearch});
    };

    render() {
        const {field, val, fieldId, onDataUpdate} = this.props;
        const {showSearch} = this.state;

        let addressToShow = val;
        try {
            let jsonAddress = JSON.parse(val);
            addressToShow = jsonAddress.formattedAddress;
        } catch (e) {
            // just ignore
        }
        {/*<small className="form-text text-muted">{"use this field to find the exact address"}</small>*/
        }
        {/*<small className="form-text text-muted">{"enter the address here if you cannot find"}</small>*/
        }
        return <div>

            {showSearch ?
                <div className={"row"}>
                    <div className={"col"}>
                        <Autocomplete
                            className={"form-control"}
                            onPlaceSelected={(place) => {
                                const dataToSave = {
                                    components: place.address_components,
                                    formattedAddress: place.formatted_address,
                                    location: place.geometry.location
                                };
                                onDataUpdate(JSON.stringify(dataToSave), field.key);
                                console.log('place', place);
                            }}
                            types={['address']}
                            // componentRestrictions={{country: "lt"}}
                        />
                    </div>
                    <div className={"col-auto"}>
                        <button onClick={(e) => {
                            e.preventDefault();
                            this.showSearch(false)
                        }}
                                className={"btn p-0"}>
                            <Icon.XCircle/>
                        </button>
                    </div>
                </div>

                :
                <div className={"row"}>
                    <div className={"col"}>
                <textarea className="form-control" id={fieldId}
                          placeholder={field.placeholder}
                          rows={5}
                          value={addressToShow}
                          onChange={(e) => onDataUpdate(e.target.value, field.key)}></textarea>
                    </div>
                    <div className={"col-auto"}>
                        <button onClick={(e) => {
                            e.preventDefault();
                            this.showSearch(true)
                        }}
                                className={"btn p-0"}>
                            <Icon.Search/>
                        </button>
                    </div>
                </div>
            }
        </div>
    }

};

export default AddressField;
