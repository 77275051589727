import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Dropzone from 'react-dropzone'
import Api from "../../../service/api";
import moment from "moment";
import FileIcon, {defaultStyles} from 'react-file-icon';

import FileViewer from 'react-file-viewer';

import {Modal, ModalBody, ModalHeader} from 'reactstrap';


class FileWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            previewFile: null
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const {extraData} = this.props;

        fetch(Api.getBackendAddress() + 'app/files/get-files/' + extraData.parentTag).then(res => res.json()).then(res => {
            this.setState({data: res.data});
        });
    }

    onAcceptFiles = (acceptedFiles) => {
        const {extraData} = this.props;

        const files = acceptedFiles;
        const formData = new FormData();
        formData.append("parentTag", extraData.parentTag);
        for (let i = 0; i < files.length; i++) {
            let fileData = files[i];
            formData.append('file_' + i, fileData);
        }

        fetch(Api.getBackendAddress() + 'app/files/upload', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                this.fetchData();
            })
            .catch(error => {
                console.error(error)
            })
    };

    assignPreviewFile = (file) => {
        this.setState({
            previewFile: file
        });
    };

    render() {
        const {editable} = this.props;
        const {data} = this.state;
        return (
            <Fragment>
                <Dropzone onDrop={this.onAcceptFiles}>
                    {({getRootProps, getInputProps}) => (
                        <div className={"card"} key={"drop-card"} {...getRootProps()}>
                            <div className={"card-header pb-2 pt-4"}>
                                <h6>Files</h6>
                            </div>
                            <div className={"card-body"}>
                                <ul className="list-group list-group-flush">
                                    {data.map((item) => {
                                        const momentTime = moment(item.createdAt);
                                        return (
                                            <li className="list-group-item">
                                                <a className="align-items-start"
                                                   onClick={() => this.assignPreviewFile(item)}>
                                                    <div className="row">
                                                        <div className={"col-2"}>
                                                            <FileIcon
                                                                extension={item.type} {...defaultStyles[item.type]} />
                                                        </div>
                                                        <div className={"col-10"} style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            <strong className="mb-1">{item.name}</strong>
                                                            <br/>
                                                            <small
                                                                title={momentTime.format('YYYY-MM-DD HH:mm')}>{momentTime.fromNow()}</small>
                                                        </div>
                                                    </div>
                                                    {/*<p className="mb-1">*/}
                                                    {/*    Donec id elit non mi porta gravida at eget metus.*/}
                                                    {/*    Maecenas sed diam eget risus varius blandit.*/}
                                                    {/*</p>*/}
                                                    {/*<small>Donec id elit non mi porta.</small>*/}
                                                </a>
                                            </li>
                                        );
                                    })}
                                    {/*{data.length === 0 && <li className="list-group-item">Drop files here</li>}*/}
                                </ul>
                            </div>

                            {/*<div className={"row"} onClick={() => this.assignPreviewFile(item)}*/}
                            {/*     key={"file-item-" + item.id}*/}
                            {/*>*/}
                            {/*<div className={"col-2"}>*/}
                            {/*    <FileIcon extension="docx" {...defaultStyles.docx} />*/}
                            {/*</div>*/}
                            {/*    <div className={"col"}>*/}
                            {/*        {item.name}<br/>*/}
                            {/*        <span className={"txt-secondary"}>{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </div>
                    )}
                </Dropzone>


                <Modal isOpen={!!this.state.previewFile} toggle={this.closeFilePreview} size="lg">
                    {this.state.previewFile &&
                    <ModalHeader toggle={this.closeFilePreview}>
                        <div className={"row"}>
                            <div className={"col"}>
                                {this.state.previewFile.name}
                            </div>
                            <div className={"col-auto"}>
                                <button className="btn btn-secondary btn-air-secondary btn-pill btn-sm"
                                        key={"download"}
                                        onClick={() => this.openFile(this.state.previewFile)}>Download
                                </button>
                                {editable &&
                                <button className="btn btn-danger btn-air-danger btn-pill btn-sm ml-2"
                                        key={"delete"}
                                        onClick={() => this.deleteFile(this.state.previewFile)}>Delete
                                </button>
                                }
                            </div>
                        </div>
                    </ModalHeader>}
                    <ModalBody>
                        {this.state.previewFile &&
                        <FileViewer
                            fileType={this.state.previewFile.type}
                            filePath={this.getFileLink(this.state.previewFile)}
                        />
                        }
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }

    closeFilePreview = () => {
        this.setState({previewFile: null});
    };

    getFileLink = (item) => {
        return Api.getBackendAddress() + 'app/files/get-file-binary/' + item.id;
    };

    openFile = (item) => {
        window.open(this.getFileLink(item), "_blank")
    };

    deleteFile = (item) => {
        if (!window.confirm('Are you sure?')) return false;

        this.closeFilePreview();

        fetch(Api.getBackendAddress() + 'app/files/delete-file/' + item.id).then(res => res.json()).then(res => {
            this.fetchData();

        });
    };
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(FileWidget);
