import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {Doughnut, Line} from 'react-chartjs-2';
import WidgetCardHeader from "../helpers/widgetCardHeader";

const lineChartData = {
    labels: ['Mon', 'Tue', 'Wen', 'Thus', 'Fri', 'Sat', 'Sun'],
    datasets: [
        {
            data: [10, 59, 80, 81, 56, 55, 40],
            backgroundColor: 'rgba(68, 102, 242, 0.3)',
            borderColor: "#4466f2",
            borderWidth: 2,
        },
        {
            data: [28, 48, 40, 19, 86, 27, 90],
            backgroundColor: 'rgba(30, 166, 236, 0.3)',
            borderColor: "#1ea6ec",
            borderWidth: 2,
        }
    ],
    plugins: {
        datalabels: {
            display: false,
            color: 'white'
        }
    }
}
const lineChartOptions = {
    maintainAspectRatio: true,
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            stacked: true,
        }],
        yAxes: [{
            stacked: true,
        }]
    },
    plugins: {
        datalabels: {
            display: false,
        }
    }
}


const doughnutData = {
    labels: ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'],
    datasets: [{
        data: [350, 450, 100],
        backgroundColor: ["#4466f2", "#1ea6ec", "#FF5370"]
    }]
}
const doughnutOption = {
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    plugins: {
        datalabels: {
            display: false,
            color: 'white'
        }
    }
}

const datasetKeyProvider = () => {
    return Math.random();
}

class RPStatsWidget extends React.Component {

    render() {
        const {formData} = this.props;

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            // variableWidth:true,
            arrows: false,
            autoplay: true
        };

        return <Fragment/>

        return (
            <Fragment>

                <div className={"card"}>
                    <WidgetCardHeader title={"This month views"}/>
                    <div className={"card-body chart-block"}>
                        <Line data={lineChartData} options={lineChartOptions} datasetKeyProvider={datasetKeyProvider}
                              width={778} height={400}/>
                    </div>
                </div>

                <div className="card">
                    <WidgetCardHeader title={"This month views"}/>
                    <div className="card-body chart-block">
                        <div className="carddoughnutData, doughnutOption,-body">
                            <Doughnut data={doughnutData} options={doughnutOption} width={778} height={400}/>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(RPStatsWidget);
