import React from 'react';
import {Link} from "react-router-dom";
import Api from "../../service/api";

class ListExtraActions extends React.Component {

    runAjax = (action, needConfirm) => {
        if (needConfirm) {
            if (!window.confirm('Are you sure?')) return false;
        }
        fetch(`${Api.getBackendAddress()}${action}`).then(res => res.json()).then(res => {
            if (res.success === 1) {
                window.location.reload();
            } else {
                alert('Error' + res.error);
            }
        })
    };

    render() {
        const {actions, size} = this.props;

        return (
            actions ? actions.map((item, index) => {
                const color = item.color ? item.color : 'light';
                if (item.type === 'link_button') {
                    return <Link
                        key={"action-" + index}
                        to={`${process.env.PUBLIC_URL}/${item.link}`}
                        className={"btn btn-" + color + " btn-pill btn-air-" + color + " mr-2" + (size === 'small' ? " btn-sm" : "")}>{item.title}</Link>
                } else if (item.type === 'ajax_button') {
                    return <button
                        key={"action-" + index}
                        onClick={() => this.runAjax(item.link, item.needConfirm)}
                        className={"btn btn-" + color + " btn-pill btn-air-" + color + " mr-2" + (size === 'small' ? " btn-sm" : "")}>{item.title}</button>
                }
            }) : []
        );
    }
}

export default ListExtraActions;
