import React, {Component, Fragment} from "react";

import {Auth0Context} from "../../../react-auth0-spa";
import ComponentTitle from "../../components/componentTitle";
import AjForm from "../../components/form/ajform";
import Api from "../../../service/api";
import LoadingComponent from "../../components/LoadingComponent";
import AjThead from "../../components/list/headers/ajthead";
import AjTbody from "../../components/list/body/ajtbody";
import NumberFormat from "react-number-format";

const defaultData = {
    formData: {
        dateFrom: null,
        dateTo: null
    },
    data: [],
    columns: [],
    parentName: 'paymentsimport',
    componentName: 'pi_entry'
};

class PientryReports extends Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);

        this.state = defaultData;
    }

    render() {
        const {history} = this.props;

        const isDateSet = this.state.formData.dateFrom || this.state.formData.dateTo;

        const paymentsData = this.state.data.filter(item => {return (!(item.isSystemPayment || item.isInternalPayment))});

        return (
            <Fragment>
                <ComponentTitle component={{title: 'Reports'}} history={history}/>

                <div className={"card"}>
                    <div className={"card-body"}>
                        {this.renderDateSetting()}
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-primary mr-1" onClick={this.filterData}>Filter
                        </button>
                        <button className="btn btn-secondary" onClick={this.resetState}>Cancel</button>
                    </div>
                </div>

                {paymentsData.length > 0 &&
                <div className={"card"}>
                    <div className={"card-header"}>
                        Results: {paymentsData.length}
                    </div>
                    <div className={"card-body"}>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                {this.renderPayments(paymentsData.filter(item => item['amount->amountAsFloat'] > 0))}
                            </div>
                            <div className={"col-6"}>
                                {this.renderPayments(paymentsData.filter(item => item['amount->amountAsFloat'] < 0))}
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.loading && <LoadingComponent/>}
            </Fragment>
        );
    }

    renderPayments = (payments) => {
        const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);

        const {columns, parentName, componentName} = this.state;
        return (
            <Fragment>
            <table className="table table-striped no-table-th-border">
                <AjThead columns={columns}/>
                <AjTbody columns={columns} data={payments} parentName={parentName} componentName={componentName}
                         isView={true}/>
            </table>
                <div>Total: <NumberFormat value={sumValues(payments.map(item => item['amount->amountAsFloat']))} displayType={'text'} thousandSeparator={','} allowNegative decimalScale={2} fixedDecimalScale/></div>
            </Fragment>
        )
    };

    resetState = () => {
        this.setState(defaultData)
    };

    filterData = () => {
        const {user} = this.context;
        const {parentName, componentName} = this.state;

        this.setState({loading: true});

        const extraQuery = {
            bookingDate: {
                fromDate: this.state.formData.dateFrom,
                toDate: this.state.formData.dateTo,
            }
        };
        Api.getTableData(parentName, componentName, extraQuery, [], 1, 9999, '', user).then(res => {
            this.setState({
                data: res.data,
                columns: res.columns,
                loading: false
            });
        }).catch(e => {
            this.setState({loading: false});
        })
    };

    renderDateSetting = () => {
        const fields = [
            {
                editPosition: 0,
                key: 'dateFrom',
                type: 'date',
                title: 'Date from'
            },
            {
                editPosition: 0,
                key: 'dateTo',
                type: 'date',
                title: 'Date to'
            }
        ];

        return <AjForm fields={fields} onDataUpdate={this.onDataUpdate}
                       formData={this.state.formData} position={0}/>
    };

    onDataUpdate = (value, key) => {
        let formData = {...this.state.formData};
        formData[key] = value;
        this.setState({
            formData: formData
        });
    };
}

export default PientryReports;
