import store from '../store/index';
import React from "react";
import Api from "../service/api";

class Components {
    constructor() {
        this.fetchItems();
    }

    fetchItems() {
        let url = Api.getBackendAddress()+'app/ui/components/get';
        fetch(url).then(res => res.json()).then(res => {
            // console.log('fetch', res);
            store.dispatch({type: 'SET_COMPONENTS', payload: res});
        })
    }

    getComponent(components, groupName, componentName) {
        if (!(components && groupName in components && componentName in components[groupName])) {
            return null;
        }
        return components[groupName][componentName];
    }
}

export const componentsInstance = new Components();
