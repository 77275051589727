import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Api from "../../../service/api";
import ListExtraActions from "../listExtraActions";

class ActionWidget extends React.Component {

    render() {
        const {extraData} = this.props;
        const {title, action} = extraData;

        return (
            <Fragment>
                <div className={"card"}>
                    <div className={"card-header b-l-primary"}>
                        <h6>{title}</h6>
                    </div>
                    <div className={"card-body"}>
                        <ListExtraActions actions={[action]} size={"small"}/>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(ActionWidget);
