import React, {Fragment} from 'react';
import {componentsInstance} from '../../../constant/components'
import AjThead from "./headers/ajthead";
import AjTbody from "./body/ajtbody";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledDropdown
} from 'reactstrap';
import Api from "../../../service/api";
import ListActions from "./listActions";
import {Auth0Context} from "../../../react-auth0-spa";
import LoadingComponent from "../LoadingComponent";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {connect} from 'react-redux'

const initialState = {
    data: [],
    columns: [],
    currentPage: 1,
    creatable: false,
    searchable: false,
    viewable: false,
    tableActions: [],
    search: '',
    loading: false,
    sort: {order: 'default', sort: 'ASC'},
    showArchive: false
};

class ListTable extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);
        this.state = {...initialState};
    }

    onSearchChange = (newSearch) => {
        this.setState({
            search: newSearch
        }, () => {
            this.fetchData(true);
        });
    };

    resetPage = () => {
        if (this.listActionsRef) {
            this.listActionsRef.resetPage();
        }
        this.setState({...initialState}, () => this.fetchData());
    };

    fetchData = (resetPaging) => {
        const {parentName, componentName, extraQuery, pageSize, hideColumns} = this.props;
        const {currentPage, search, sort, showArchive} = this.state;
        const {user} = this.context;

        this.setState({loading: true});

        let page = resetPaging ? 1 : currentPage;
        if (resetPaging) {
            this.setState({
                currentPage: 1
            });
        }

        Api.getTableData(parentName, componentName, extraQuery, hideColumns, page, pageSize, search, user, sort, showArchive).then(res => {
            if (res.success === 1) {
                this.setState({
                    data: res.data,
                    columns: res.columns,
                    creatable: res.creatable,
                    searchable: res.searchable,
                    viewable: res.viewable,
                    tableActions: res.actions,
                    loading: false,
                    sort: res.order
                });
            } else {
                toast.error('Error fetching data', {position: toast.POSITION.BOTTOM_RIGHT});
            }
        }).catch(e => {
            this.setState({loading: false});
            toast.error('Error fetching data', {position: toast.POSITION.BOTTOM_RIGHT});
        })
    };

    componentDidMount() {
        this.fetchData();
    }

    prevPage = () => {
        this.setState({
            currentPage: Math.max(this.state.currentPage - 1, 1)
        }, () => {
            this.fetchData()
        });
    };

    nextPage = () => {
        this.setState({
            currentPage: this.state.currentPage + 1
        }, () => {
            this.fetchData()
        });
    };

    loadPage = (page) => {
        this.setState({
            currentPage: page
        }, () => {
            this.fetchData()
        });
    };

    onSortChange = (newSort) => {
        const {sort} = this.state;
        if (sort.order !== newSort) {
            this.setState({
                sort: {order: newSort, sort: 'ASC'}
            }, () => {
                this.fetchData(true)
            });
        } else {
            this.setState({
                sort: {order: newSort, sort: sort.sort === 'ASC' ? 'DESC' : 'ASC'}
            }, () => {
                this.fetchData(true)
            });
        }
    };

    onShowArchive = (showArchive) => {
        this.setState({showArchive}, () => this.fetchData(true));
    }

    render() {
        const {parentName, componentName, isView, actionsExtraQuery, title, actionsSize} = this.props;
        const {components} = this.props;
        const {columns, data, searchable, viewable, sort, showArchive} = this.state;

        let component = componentsInstance.getComponent(components, parentName, componentName);
        if (!component) {
            return <div></div>;
        }

        return (
            <Fragment>
                <div className="pb-2 pt-4">
                    <div className={"row"}>

                        <div className={"col"}>
                            <ListActions component={component} creatable={this.state.creatable}
                                         extraQuery={actionsExtraQuery}
                                         tableActions={this.state.tableActions}
                                         size={actionsSize}
                                         title={title}
                                         onSearchChange={this.onSearchChange}
                                         searchable={searchable}
                                         ref={(ref) => this.listActionsRef = ref}
                            />
                        </div>
                        <div className={"col-auto"}>
                            <UncontrolledDropdown>
                                <DropdownToggle caret
                                                className="btn btn-light  btn-pill btn-sm ml-2">
                                    {showArchive ? 'Archive' : 'Active'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        onClick={() => this.onShowArchive(false)}>Active</DropdownItem>
                                    <DropdownItem
                                        onClick={() => this.onShowArchive(true)}>Archive</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                </div>

                {data.length > 0 ?
                    <table className="table table-striped no-table-th-border">
                        <AjThead columns={columns} onSortChange={this.onSortChange} sort={sort}/>
                        <AjTbody columns={columns} data={data} parentName={parentName} componentName={componentName}
                                 isView={isView} viewable={viewable}/>
                    </table>
                    :
                    this.state.loading ? '' : <div className={"row"}>
                        <div className={"col txt-grey"}>
                            No records found
                        </div>
                    </div>
                }

                {(data.length > 0 || this.state.currentPage !== 1) &&
                <Pagination aria-label="Page navigation" className="pagination-primary mt-4">
                    {this.state.currentPage > 2 && (
                        <PaginationItem>
                            <a onClick={() => this.loadPage(1)} className="page-link">
                                <span aria-hidden="true">«</span>
                            </a>
                        </PaginationItem>
                    )
                    }
                    <PaginationItem disabled={this.state.currentPage === 1}>
                        <a onClick={this.prevPage} className="page-link">
                            <span aria-hidden="true">‹</span>
                        </a>
                    </PaginationItem>
                    <PaginationItem active>
                        <PaginationLink>
                            {this.state.currentPage}
                        </PaginationLink>
                    </PaginationItem>

                    <PaginationItem>
                        <a onClick={this.nextPage} className="page-link">
                            <span aria-hidden="true">›</span>
                        </a>
                    </PaginationItem>
                </Pagination>
                }
                {this.state.loading && <LoadingComponent/>}
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(ListTable);
