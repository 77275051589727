import React from 'react';
import AsyncSelect from 'react-select/async';
import Api from "../../../../service/api";
import {useAuth0} from "../../../../react-auth0-spa";

const ManytooneField = (props) => {
    const {field, val, placeholder} = props;
    const {selectParent, selectComponent, key, extraQuery} = field;
    const {loading, user} = useAuth0();

    const loadOptions = (inputValue, callback) => {


        let url = Api.getBackendAddress() + 'app/ui/data/get/search?';
        url += 'parent=' + selectParent;
        url += '&component=' + selectComponent;
        url += '&search=' + encodeURIComponent(inputValue);
        url += '&extraQuery=' + JSON.stringify(extraQuery ? extraQuery : {});
        url += '&ajUserId=' + user.sub;

        fetch(url).then(res => res.json()).then(res => {
            callback(res.data);
        })
    };

    return (
        <AsyncSelect
            placeholder={placeholder}
            cacheOptions
            value={val}
            loadOptions={loadOptions}
            onChange={(selected) => props.onDataUpdate(selected, key)}
            defaultOptions
            formatOptionLabel={(selected) => selected.name}
            isOptionSelected={(selected) => selected === val}
        />
    );
};

export default ManytooneField;
