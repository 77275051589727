import {SET_COMPONENTS} from "../constant/actionTypes";

const initial_state = {
    components: null
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_COMPONENTS:
            return {...state, components: action.payload};

        default:
            return {...state};
    }
}
