import React from 'react';
import DateHeader from "./date";

const AjThead = (props) => {
    const {columns, isField, isView, onSortChange, sort} = props;

    let idClassNames = ["cursor-pointer"];

    if (sort && (sort.order === 'a.id' || sort.order === 'default')) {
        if (sort.sort === 'ASC') {
            idClassNames.push('table-order-asc');
        } else {
            idClassNames.push('table-order-desc');
        }
    }

    return (
        <thead>
        <tr>
            <th scope="col" className={idClassNames.join(" ")}>#</th>
            {columns.map((item, index) => {
                let className = ["cursor-pointer"];
                if (item.type === 'float') {
                    className.push('text-right');
                }
                if (item.extraClassName) {
                    className.push(item.extraClassName);
                }
                if (item.type === 'date') {
                    return <DateHeader column={item} key={'th-' + item.key}/>
                }

                if (sort && sort.order === item.sortKey) {
                    if (sort.sort === 'ASC') {
                        className.push('table-order-asc');
                    } else {
                        className.push('table-order-desc');
                    }
                }

                return <th key={'th-' + index + '-' + item.key} scope="col"
                           className={className.join(" ")}
                           onClick={() => {
                               if (onSortChange) {
                                   onSortChange(item.sortKey)
                               }
                           }}>{item.title}</th>;
            })}
            {isField && !isView && <th scope="col"></th>}
        </tr>
        </thead>
    );
};

export default AjThead;
