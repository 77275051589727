class VatDataManager {

    doJob(formData, value, key, childIndex, childKey, extraData) {
        console.log('VatDataManager', key, childIndex);
        return new Promise((resolve, reject) => {
            this.dataToUpdate = [];

            this.formData = formData;
            this.value = value;

            this.extraData = extraData;

            try {
                this.recalculatePriceWVat();
            } catch (e) {
                console.log('error', e);
            }

            resolve({dataToUpdate: this.dataToUpdate, formData: this.formData});
        });
    }

    recalculatePriceWVat() {
        let lines = this.formData[this.extraData.key];
        for (let i = 0; i < lines.length; i++) {
            this.calculatePriceWVat(i);
        }
    }

    calculatePriceWVat(index) {
        let key = this.extraData.key;
        let vatField = this.extraData.vatField;

        let vat = parseInt(this.formData[vatField] ? this.formData[vatField].name : 0, 10);
        let element = this.formData[key][index];
        let oldPriceWVat = element.priceWVat;
        element.priceWVat = element.price * (1 + (vat / 100));
        this.formData[key][index] = element;

        if (oldPriceWVat !== element.priceWVat) {
            this.dataToUpdate.push({
                key: key,
                val: this.formData[key],
                childIndex: index,
                childKey: 'priceWVat'
            });
        }

    }
}

export default VatDataManager;
