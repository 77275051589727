import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Api from "../../../../service/api";
import {MapPin} from "react-feather";
import CountUp from 'react-countup';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class RpScriptWidget extends React.Component {

    render() {
        const {formData} = this.props;

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            // variableWidth:true,
            arrows: false,
            autoplay: true
        };

        return (
            <Fragment>
                <div className={"card"}>
                    <div className={"card-body"}>
                        <a className="btn btn-primary btn-air-primary btn-pill btn-sm"
                           target="_blank"
                           href={Api.getBackendAddress() + 'app/rp/preview/' + formData.uniqId}>Preview</a>
                    </div>
                </div>

                <div className={"card"}>
                    <div className={"card-body"}>
                        <p>Please include this script into page:</p>
                        <code>{`<script type="text/javascript" src="${Api.getBackendAddress()}app/rp/public/${formData.uniqId}"></script>`}</code>
                    </div>
                </div>
                {formData.stats.all > 0 &&
                <div className={"row"}>
                    <div className="col-12 ecommerce-slider">
                        <Slider {...settings}>
                            <div className={"item"}>
                                <div className="card">
                                    <div className="card-body ecommerce-icons text-center">
                                        <MapPin/>
                                        <div><span>Today views</span></div>
                                        <h4 className="font-primary mb-0">
                                            <CountUp className="counter" end={formData.stats.today}/></h4>
                                    </div>
                                </div>
                            </div>

                            <div className={"item"}>
                                <div className="card">
                                    <div className="card-body ecommerce-icons text-center">
                                        <MapPin/>
                                        <div><span>This month views</span></div>
                                        <h4 className="font-primary mb-0">
                                            <CountUp className="counter" end={formData.stats.month}/></h4>
                                    </div>
                                </div>
                            </div>


                            <div className={"item"}>
                                <div className="card">
                                    <div className="card-body ecommerce-icons text-center">
                                        <MapPin/>
                                        <div><span>Total views</span></div>
                                        <h4 className="font-primary mb-0">
                                            <CountUp className="counter" end={formData.stats.all}/></h4>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(RpScriptWidget);
