import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateField = (props) => {
    const {field, val} = props;

    let dateVal = val;
    if (typeof val !== 'object') {
        dateVal = new Date(dateVal);
    }
    return (

        <DatePicker className="form-control digits"
                    dateFormat={"yyyy-MM-dd"}
                    selected={dateVal}
                    onChange={(date) => props.onDataUpdate(date, field.key)}/>

    );
};

export default DateField;
