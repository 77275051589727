import Api from "../../service/api";
import React, {Fragment} from "react";

class SubscriptionPlans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            period: 'monthly'
        }
    }

    componentDidMount() {
        this.loadOptions();
    }

    loadOptions = () => {
        const {user} = this.props;

        const planField = {
            selectComponent: 'stripe_plan',
            selectParent: 'stripe',
            title: 'Plan',
            type: 'many-to-one',
            key: 'plan',
            placeholder: '',
            description: '',
        };


        let url = Api.getBackendAddress() + 'app/ui/data/get/table?';
        url += 'parent=' + planField.selectParent;
        url += '&component=' + planField.selectComponent;
        url += '&hideColumns=' + JSON.stringify({});
        url += '&extraQuery=' + JSON.stringify({public: true});
        url += '&ajUserId=' + user.sub;

        fetch(url).then(res => res.json()).then(res => {
            this.setState({
                plans: res.data
            });
        })
    };

    setPeriod = (period) => {
        this.setState({
            period: period
        });
    }

    render() {
        const {planSelected, selected} = this.props;
        return (
            <Fragment>
                <div className="row mb-4">
                    <div className={"col"}></div>
                    <div className="col-xl-4 col-md-6 col-sm-12 text-center">
                        <div className="btn-group btn-group-pill" role="group" aria-label="Payment period">
                            <button
                                className={"btn " + (this.state.period === 'monthly' ? 'btn-primary' : "btn-outline-primary")}
                                onClick={() => this.setPeriod('monthly')}
                                type="button">Monthly
                            </button>
                            <button
                                className={"btn " + (this.state.period !== 'monthly' ? 'btn-primary' : "btn-outline-primary")}
                                onClick={() => this.setPeriod('annual')}
                                type="button">Annual
                            </button>
                        </div>
                    </div>
                    <div className={"col"}></div>
                </div>

                <div className={"row"}>

                    {this.state.plans.filter(plan => plan.subscribeData.period === this.state.period).map(plan => {
                        const item = plan.subscribeData;
                        const isMonthly = item.period === 'monthly';
                        const x = isMonthly ? 197.3096 : 247.3096;
                        const isSelected = selected && selected.id === plan.id;
                        return (
                            <div className="col-xl-4 col-md-4">
                                <div className="pricing-block card text-center">
                                    {isSelected &&
                                    <div className="ribbon ribbon-bookmark ribbon-success">Selected</div>}
                                    {item.isPopular && !isSelected &&
                                    <div className="ribbon ribbon-bookmark ribbon-danger">Popular</div>}

                                    <svg x="0" y="0" viewBox="0 0 360 220">
                                        <g>
                                            <path
                                                d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                          c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                          s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                          L0.732,193.75z"/>
                                        </g>
                                        <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff"
                                              font-size="78.4489">${item.price[0]}
                                        </text>
                                        <text transform={"matrix(0.9566 0 0 1 " + x + " 83.9121)"} fill="#fff"
                                              font-size="29.0829">.{item.price[1]}
                                        </text>
                                        <text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff"
                                              font-size="15.4128">{isMonthly && '/Month'}
                                        </text>
                                    </svg>
                                    <div className="pricing-inner">
                                        <h3 className="font-primary">{item.title}</h3>
                                        <ul className="pricing-inner">
                                            {item.description.split("\n").map(line => {
                                                return (
                                                    <li>
                                                        <h6>{line}</h6>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                        <button className="btn btn-primary btn-lg" type="button"
                                                onClick={() => planSelected(plan, 'plan')}>Subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </Fragment>
        );
    }
}

export default SubscriptionPlans;
