import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './assets/custom/custom.css'
import * as serviceWorker from './serviceWorker';
// ** Import custom components for redux**
// sample page
// components
import AppWrapper from "./app";
import {Auth0Provider} from "./react-auth0-spa";

// Import custom Components
import config from "./auth_config.json";
import history from "./utils/history";

if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
    console.warn = () => {};
    console.info = () => {};
}


//firebase Auth
function Root() {
    useEffect(() => {
        const themeColor = localStorage.getItem('theme-color')
        const layout = localStorage.getItem('layout_version')
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${themeColor}.css`);
        document.body.classList.add(layout);
    }, []);
    const onRedirectCallback = appState => {
        history.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    };
    return (
        <Auth0Provider
            domain={config.domain}
            client_id={config.clientId}
            redirect_uri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            <AppWrapper/>
        </Auth0Provider>
    );
}

ReactDOM.render(<Root/>, document.getElementById('root'));

serviceWorker.unregister();
