import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {componentsInstance} from '../../constant/components'

import AjForm from "./form/ajform";
import ComponentTitle from "./componentTitle";
import Api from "../../service/api";
import {Auth0Context} from "../../react-auth0-spa";
import RpEditWidget from "./widgets/RP/RpEditWidget";

import InvoiceTotalWidget from "./widgets/Invoices/InvoiceTotalWidget";

import {DefaultCompanyValuesDataManager, InvoiceLineDataManager, VatDataManager} from "./DataManager/index";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Widgets = {
    RpEditWidget: RpEditWidget,
    InvoiceTotalWidget: InvoiceTotalWidget
};

const DataManager = {
    InvoiceLineDataManager: new InvoiceLineDataManager(),
    VatDataManager: new VatDataManager(),
    DefaultCompanyValuesDataManager: new DefaultCompanyValuesDataManager()
};

const widgetInclude = (widgetBlock, formData, onDataUpdate, index) => {
    // component does exist
    if (typeof Widgets[widgetBlock.componentName] !== "undefined") {
        return React.createElement(Widgets[widgetBlock.componentName], {
            formData: formData,
            onDataUpdate: onDataUpdate,
            key: "widget-" + formData.id + "-" + index
        });
    }
    // component doesn't exist yet
    return React.createElement(
        () => <div key={"widget-" + formData['id'] + '-' + index}>The component {widgetBlock.componentName} has not been
            created yet.</div>
    );
};

class ComponentUpdate extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            formData: {},
            widgets: [],
            contentWidgets: [],
            fieldsToReact: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.fetchData();
    }

    fetchData = () => {
        this.setState({
            loading: true
        });
        const {groupName, componentName, id} = this.props.match.params;
        const {user} = this.context;

        Api.getUpdateData(groupName, componentName, id, user).then(res => {
            if (res.success === 1) {
                this.setState({
                    fields: res.fields,
                    formData: res.formData,
                    widgets: res.widgets,
                    contentWidgets: res.contentWidgets,
                    fieldsToReact: res.fieldsToReact,
                    loading: false
                }, () => {

                });
            } else {
                this.setState({
                    loading: false
                });
            }
        }).catch(e => {
            this.setState({
                loading: false
            });
        });
    };

    saveData = () => {
        const {user} = this.context;
        const {groupName, componentName, id} = this.props.match.params;
        const {history} = this.props;

        let url = Api.getBackendAddress() + 'app/ui/data/save/update?parent=' + groupName + '&component=' + componentName + '&id=' + id;

        let formData = new FormData();
        formData.append('data', JSON.stringify(this.state.formData));
        formData.append('ajUserId', user.sub);

        for (let i = 0; i < this.state.fields.length; i++) {
            let field = this.state.fields[i];
            const fieldId = "field" + field.key;

            if (field.type === 'file') {
                const fileField = document.getElementById(fieldId);
                formData.append(field.key, fileField.files[0]);
            }
        }

        fetch(url, {
            method: 'POST',
            body: formData,
        }).then(res => res.json()).then(res => {
            if (res.success === 1) {
                history.goBack()
            } else {
                toast.error(res.error, {position: toast.POSITION.BOTTOM_RIGHT});
            }
        })
    };

    goBack = () => {
        this.props.history.goBack();
    };

    componentDidMount() {
        this.fetchData();
    }

    onDataUpdate = (value, key, childIndex, childKey) => {
        const {user} = this.context;

        let formData = {...this.state.formData};
        formData[key] = value;

        const updatedField = childKey ? key + '.' + childKey : key;

        if (updatedField in this.state.fieldsToReact) {
            const dataManagerClass = this.state.fieldsToReact[updatedField].function;
            if (dataManagerClass in DataManager) {
                const extraData = this.state.fieldsToReact[updatedField].extraData;
                DataManager[dataManagerClass].doJob(formData, value, key, childIndex, childKey, extraData, user).then(params => {
                    const {formData, dataToUpdate} = params;
                    if (formData) {
                        this.setState({
                            formData: formData
                        });
                    }
                    if (dataToUpdate) {
                        dataToUpdate.map((item, key) => {
                            setTimeout(
                                () => {
                                    this.onDataUpdate(item.val, item.key, item.childIndex, item.childKey);
                                },
                                key * 300
                            );

                        });
                    }
                });
            }
        } else {
            this.setState({
                formData: formData
            });
        }
    };

    render() {
        const {groupName, componentName, id} = this.props.match.params;
        const {components, history} = this.props;
        const {formData, fields} = this.state;

        let component = componentsInstance.getComponent(components, groupName, componentName);
        if (!component) {
            return <div></div>;
        }

        const isRightFields = fields.filter((item) => item.editPosition === 1).length > 0;

        const buttonsBlock = <Fragment>
            <button className="btn btn-primary mr-1" onClick={this.saveData}>Save
            </button>
            <button className="btn btn-secondary" onClick={this.goBack}>Cancel</button>
        </Fragment>;

        const isRightBlock = (isRightFields || this.state.widgets.length > 0);
        const isContentWidgets = this.state.contentWidgets.length > 0;

        return (
            <Fragment>
                <div className="container-fluid">
                    <ComponentTitle component={component} history={history}/>

                    <div className={"row"}>
                        <div className={isRightBlock ? "col-8" : "col-12"}>
                            <div className="card">

                                <div className="card-body">

                                    <AjForm fields={fields} onDataUpdate={this.onDataUpdate}
                                            formData={formData} position={0}/>

                                </div>
                                {!isContentWidgets &&
                                <div className="card-footer">
                                    {buttonsBlock}
                                </div>
                                }
                            </div>

                            {isContentWidgets &&
                            this.state.contentWidgets.map((item, index) => widgetInclude(item, formData, this.onDataUpdate, index))
                            }

                            {isContentWidgets && <div className={"card"}>
                                <div className={"card-body"}>
                                    {buttonsBlock}
                                </div>
                            </div>}
                        </div>
                        {isRightBlock &&
                        <div className="col-4">
                            {isRightFields &&
                            <div className="card">
                                <div className="card-body">

                                    <AjForm fields={fields} onDataUpdate={this.onDataUpdate}
                                            formData={formData} position={1}/>

                                </div>
                            </div>
                            }
                            {this.state.widgets.length > 0 &&
                            this.state.widgets.map((item, index) => widgetInclude(item, formData, this.onDataUpdate, index))
                            }
                        </div>
                        }

                    </div>

                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(ComponentUpdate);
