import React, {Fragment} from 'react';

import WidgetCardHeader from "../helpers/widgetCardHeader";

// import verifiedIcon from "../../../../../public/ajpp-client/blackfomo/verified.png"

class RpEditWidget extends React.Component {

    render() {
        const {formData, onDataUpdate} = this.props;

        const productNames = formData.products ? formData.products.map(item => item.title) : [];
        const actionName = formData.actionName;
        const productName = productNames.length > 0 ? productNames[0] : 'Product name here';
        const design = formData.notificationDesign;

        const productImageUrl = formData.thumbnailUrl ? formData.thumbnailUrl : "http://icons.iconarchive.com/icons/custom-icon-design/flatastic-6/256/Square-icon.png";
        const rect = (index) => index === design ? '/ajpp-client/blackfomo/notification/green-rect.png' : '/ajpp-client/blackfomo/notification/blue-rect.png';
        const verifiedIcon = '/ajpp-client/blackfomo/notification/verified.png';
        const verifiedIconBlack = '/ajpp-client/blackfomo/notification/verified_black.png';

        return (
            <Fragment>
                <div className={"card"}>
                    <WidgetCardHeader title={"Choose design"}/>

                    <div className={"card-body"}>
                        <div style={style1.box} onClick={() => onDataUpdate(0, 'notificationDesign')}>
                            <img
                                src={rect(0)}
                                style={style1.img}/>
                            <div style={style1.textBox}>
                                <div style={style1.textLine1}><span style={style1.textName}>Minna H.</span> Berlin,
                                    Berlin 🇩🇪
                                </div>
                                <div style={style1.textLine2}>{actionName}: {productName}</div>
                                <div style={style1.textLine3Verified}><span
                                    style={style1.textLine3Time}>43 minutes ago</span> <img
                                    style={style1.verifiedIcon}
                                    src={verifiedIcon}/> Verified
                                </div>
                            </div>
                        </div>

                        <br/><br/>

                        <div style={style1Black.box} onClick={() => onDataUpdate(1, 'notificationDesign')}>
                            <img
                                src={rect(1)}
                                style={style1Black.img}/>
                            <div style={style1Black.textBox}>
                                <div style={style1.textLine1}><span style={style1.textName}>Minna H.</span> Berlin,
                                    Berlin 🇩🇪
                                </div>
                                <div style={style1.textLine2}>{actionName}: {productName}</div>
                                <div style={style1.textLine3Verified}><span
                                    style={style1.textLine3Time}>43 minutes ago</span> <img
                                    style={style1.verifiedIcon}
                                    src={verifiedIconBlack}/> Verified
                                </div>
                            </div>
                        </div>

                        <br/><br/>

                        <div style={style2.box} onClick={() => onDataUpdate(2, 'notificationDesign')}>
                            <img
                                src={rect(2)}
                                style={style2.img}/>
                            <div style={style1.textBox}>
                                <div style={style1.textLine1}><span style={style1.textName}>Minna H.</span> Berlin,
                                    Berlin 🇩🇪
                                </div>
                                <div style={style1.textLine2}>{actionName}: {productName}</div>
                                <div style={style1.textLine3Verified}><span
                                    style={style1.textLine3Time}>43 minutes ago</span> <img
                                    style={style1.verifiedIcon}
                                    src={verifiedIcon}/> Verified
                                </div>
                            </div>
                        </div>

                        <br/><br/>

                        <div style={style2Black.box} onClick={() => onDataUpdate(3, 'notificationDesign')}>
                            <img
                                src={rect(3)}
                                style={style2Black.img}/>
                            <div style={style1Black.textBox}>
                                <div style={style1.textLine1}><span style={style1.textName}>Minna H.</span> Berlin,
                                    Berlin 🇩🇪
                                </div>
                                <div style={style1.textLine2}>{actionName}: {productName}</div>
                                <div style={style1.textLine3Verified}><span
                                    style={style1.textLine3Time}>43 minutes ago</span> <img
                                    style={style1.verifiedIcon}
                                    src={verifiedIconBlack}/> Verified
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

const style1 = {
    box: {
        minHeight: 108,
        width: '100%',
        maxWidth: 600,
        padding: 4,
        boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.25)",
        borderRadius: 10,
        backgroundColor: "#FFF",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    img: {width: 100, height: 100, borderRadius: 8.9},
    textBox: {
        marginLeft: 20,
        lineHeight: '180%',
        maxWidth: '70%'
    },
    textLine1: {
        fontSize: 14
    },
    textName: {
        fontWeight: 'bold',
        fontSize: 16
    },
    textLine2: {
        fontWeight: 'bold',
        fontSize: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden'

    },
    textLine3Time: {
        color: "#8b8b8b",
        fontSize: 12
    },
    textLine3Verified: {
        color: "#4EAE01",
        fontSize: 12,
        fontWeight: 'bold'
    },
    verifiedIcon: {
        width: 12,
        height: 12,
        marginLeft: 15
    }
};
const style1Black = {
    box: {
        minHeight: 108,
        maxWidth: 600,
        width: '100%',
        padding: 4,
        boxShadow: "0px 0px 10px 1px rgba(255,255,255,0.55)",
        borderRadius: 10,
        backgroundColor: "#323232",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    img: {width: 100, height: 100, borderRadius: 8.9},


    textBox: {
        marginLeft: 20,
        lineHeight: '180%',
        maxWidth: '70%',
        color: "#FFF"
    }
};

const style2 = {
    box: {
        minHeight: 104,
        maxWidth: 600,
        width: '100%',
        padding: 4,
        boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.25)",
        borderRadius: 55,
        backgroundColor: "#FFF",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    img: {width: 100, height: 100, borderRadius: 50}
};
const style2Black = {
    box: {
        minHeight: 104,
        maxWidth: 600,
        width: '100%',
        padding: 4,
        boxShadow: "0px 0px 10px 1px rgba(255,255,255,0.55)",
        borderRadius: 55,
        backgroundColor: "#323232",
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    img: {width: 100, height: 100, borderRadius: 50}
};

export default RpEditWidget;
