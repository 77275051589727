import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {componentsInstance} from '../../../constant/components'
import AjThead from "./headers/ajthead";
import AjTbody from "./body/ajtbody";
import Api from "../../../service/api";
import {Auth0Context} from "../../../react-auth0-spa";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AddEmptyAction from "./linesTableActions/AddEmptyAction";
import AddServiceAction from "./linesTableActions/AddServiceAction";

const TableActions = {
    AddEmptyAction: AddEmptyAction,
    AddServiceAction: AddServiceAction
};

const actionInclude = (tableActionBlock, formData, index, addData, user) => {
    // component does exist
    if (typeof TableActions[tableActionBlock.componentName] !== "undefined") {
        return React.createElement(TableActions[tableActionBlock.componentName], {
            formData: formData,
            addData,
            user,
            key: "table-action-" + formData.id + "-" + index
        });
    }
    // component doesn't exist yet
    return React.createElement(
        () => <div key={"table-action-" + formData['id'] + '-' + index}>The
            component {tableActionBlock.componentName} has not been
            created yet.</div>
    );
};

class LinesTable extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: []
        };
    }

    fetchData = () => {
        const {parentName, componentName, extraQuery, copyFrom} = this.props;
        const {user} = this.context;

        Api.getTableData(parentName, componentName, extraQuery, {}, 1, 9999, '', user).then(res => {
            if (res.success === 1) {
                this.setState(
                    {
                        data: res.data,
                        columns: res.columns
                    },
                    () => {
                        this.props.onDataUpdate(this.state.data);
                    }
                );
            } else {
                toast.error('Error fetching data', {position: toast.POSITION.BOTTOM_RIGHT});
            }
        }).catch(e => {
            toast.error('Error fetching data', {position: toast.POSITION.BOTTOM_RIGHT});
        })
    };

    onDataRemove = (id) => {
        let data = [...this.state.data];
        let newData = [];

        data.map(item => {
            if (item.id !== id) {
                newData.push(item);
            }
        });

        this.setState({
            data: newData
        }, () => {
            this.props.onDataUpdate(this.state.data);
        });
    };

    addData = (newData) => {
        const {user} = this.context;
        const {parentName, componentName} = this.props;

        let data = [...this.state.data];

        Api.getNewData(parentName, componentName, null, user).then(res => {
            let item = {
                ...res.formData,
                ...newData,
                id: new Date().getTime()
            };
            data.push(item);
            this.setState({
                data: data
            }, () => {
                this.props.onDataUpdate(this.state.data, this.state.data.length - 1, '__new');
            });
        });


    };

    addProductData = (product) => {
        this.addData({
            product: product,
            name: product.name
        });
    };

    onDataUpdate = (index, fieldKey, fieldValue) => {
        let data = [...this.state.data];
        let item = data[index];
        item[fieldKey] = fieldValue;

        this.setState({
            data: data
        }, () => {
            this.props.onDataUpdate(this.state.data, index, fieldKey);
        });
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (prevState.data !== this.state.data) {
        //     this.props.onDataUpdate(this.state.data);
        // }
    }

    render() {
        const {parentName, componentName, isView, isField, actions} = this.props;
        const {components} = this.props;
        const {data, columns} = this.state;
        const {user} = this.context;

        let component = componentsInstance.getComponent(components, parentName, componentName);
        if (!component) {
            return <div></div>;
        }

        return (
            <Fragment>
                <table className="table table-striped no-table-th-border mt-2 mb-2">
                    <AjThead columns={columns} isView={isView} isField={isField}/>
                    <AjTbody columns={columns} data={data} parentName={parentName} componentName={componentName}
                             isView={isView}
                             onDataUpdate={this.onDataUpdate}
                             onDataRemove={this.onDataRemove}
                             isField={isField}/>
                </table>

                {!isView && actions.length > 0 &&
                <div className={"row"}>
                    {actions.map((item, index) => actionInclude({componentName: item}, data, index, this.addData, user))}
                </div>
                }
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(LinesTable);
