import React from 'react';
import {Link} from "react-router-dom";

const ManyToOneColumn = (props) => {
    const {column, val} = props;

    const displayVal = val ? val.name : '';
    const clickable = column.clickable && displayVal;

    return (
        clickable ?
            <Link
                to={`${process.env.PUBLIC_URL}/components/${column.selectParent}/${column.selectComponent}/view/${val.id}`}>{displayVal}</Link> : displayVal

    );
};

export default ManyToOneColumn;
