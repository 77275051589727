import React from 'react';

const DateHeader = (props) => {
    const {column} = props;

    return (
        <th key={'th-' + column.key} scope="col"
            className="text-center">{column.title}</th>
    );
};

export default DateHeader;
