import React from 'react';

const AjEnumColumn = (props) => {
    const {column, val} = props;

    return (
        val && val.name ?
            val.name : ''

    );
};

export default AjEnumColumn;
