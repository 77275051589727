import React, {Component, Fragment} from "react";
import {Col, Row} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, {Draggable} from "@fullcalendar/interaction";

import 'sweetalert2/src/sweetalert2.scss'

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import ComponentTitle from "../componentTitle";
import Api from "../../../service/api";
import {Auth0Context} from "../../../react-auth0-spa";
import moment from "moment";

class FpnCalendar extends Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);

        this.state = {
            calendarEvents: [],
            events: []
        };
    }


    fetchData = (onlyExternal) => {
        const pageSize = 10000;
        const page = 1;
        const parentName = 'fpn';
        const componentName = 'fpn_events';
        const extraQuery = {};
        const {user} = this.context;
        const addColumns = ['shortName'];

        Api.getTableData(parentName, componentName, extraQuery, null, page, pageSize, '', user, null, 0, addColumns).then(res => {
            const data = res.data;
            let calendarEvents = [];
            let events = [];

            data.map((item) => {
                if (item.eventDate) {
                    calendarEvents.push({
                        title: item._raw_shortName,
                        start: new Date(item.eventDate),
                        id: "" + item.id,
                        backgroundColor: this.getColorByStatus(item.status)
                    });
                } else {
                    events.push({
                        title: item._raw_shortName,
                        id: "" + item.id,
                        backgroundColor: this.getColorByStatus(item.status)
                    });
                }
            });

            if (onlyExternal) {
                this.setState({
                    events: events
                });
            } else {
                this.setState({
                    calendarEvents: calendarEvents,
                    events: events
                });
            }
        })
    };

    /**
     * adding dragable properties to external events through javascript
     */
    componentDidMount() {
        let draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: (eventEl) => {
                let title = eventEl.getAttribute("title");
                let id = eventEl.getAttribute("data");
                let status = eventEl.getAttribute("data-status");
                let data = {
                    title: title,
                    id: id,
                    color: this.getColorByStatus(status)
                };
                return data;
            }
        });

        this.fetchData();
    }

    getColorByStatus = (status) => {
        if (status === 'new') {
            return '#1ea6ec';
        } else if (status === 'accepted') {
            return '#007bff';
        } else if (status === 'done') {
            return '#22af47';
        } else if (status === 'cancel') {
            return '#ff9f40';
        }
        return '';
    };

    eventDrop = (data) => {
        console.log('event drop');
        this.eventReceive(data);
    };

    eventReceive = (data) => {
        const {user} = this.context;
        const {event} = data;

        let groupName = 'fpn';
        let componentName = 'fpn_events';

        let url = Api.getBackendAddress() + 'app/ui/data/save/changeField?parent=' + groupName + '&component=' + componentName + '&id=' + event.id;

        let formData = new FormData();
        formData.append('ajUserId', user.sub);
        formData.append('key', 'eventDate');
        formData.append('value', moment(event.start).format("YYYY-MM-DD"));
        formData.append('type', 'date');

        fetch(url, {
            method: 'POST',
            body: formData,
        }).then(res => res.json()).then(res => {
            if (res.success === 1) {
                this.fetchData(true);
            }
        })
    };

    /**
     * when we click on event we are displaying event details
     */
    eventClick = eventClick => {
        window.open(`${process.env.PUBLIC_URL}/components/fpn/fpn_events/view/${eventClick.event.id}`, '_blank');
    };

    render() {
        const {history} = this.props;

        return (
            <Fragment>
                <ComponentTitle component={{title: 'Calendar'}} history={history}/>
                {/*<Breadcrumb parent="Calender" title="Draggable Calender" />*/}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Events planning</h5>
                                </div>
                                <div className="card-body">
                                    <div className="animated fadeIn demo-app">
                                        <Row>
                                            <Col xl={3} md={4}>
                                                <div
                                                    id="external-events"
                                                >
                                                    <p align="center">
                                                        <strong>New events</strong>
                                                    </p>
                                                    {this.state.events.map(event => (
                                                        <div
                                                            className="fc-event"
                                                            title={event.title}
                                                            data={event.id}
                                                            data-status={event.status}
                                                            key={event.id}
                                                        >
                                                            {event.title}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>

                                            <Col xl={9} md={8}>
                                                <div className="demo-app-calendar" id="mycalendartest">
                                                    <FullCalendar
                                                        defaultView="dayGridMonth"
                                                        header={{
                                                            left: "prev,next today",
                                                            center: "title",
                                                            right: "dayGridMonth"
                                                        }}
                                                        rerenderDelay={10}
                                                        eventDurationEditable={false}
                                                        editable={true}
                                                        droppable={true}
                                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                        events={this.state.calendarEvents}
                                                        eventDrop={this.eventReceive}
                                                        eventReceive={this.eventReceive}
                                                        eventClick={this.eventClick}
                                                        firstDay={1}
                                                        weekNumbers={true}
                                                        displayEventTime={false}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>


        );
    }
}

export default FpnCalendar;
