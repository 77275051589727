import React, {Fragment, useEffect} from 'react';
import './index.scss';
import './assets/custom/custom.css'

import {BrowserRouter, Switch} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';
// ** Import custom components for redux**
import {Provider} from 'react-redux';
import store from './store/index';
import App from "./components/app";
// sample page
// components
import componentList from "./components/components/componentList";
import componentNew from "./components/components/componentNew";
import componentView from "./components/components/componentView";
import componentUpdate from "./components/components/componentUpdate";
import {useAuth0} from "./react-auth0-spa";
import PrivateRoute from "./components/PrivateRoute";

import Wrapper from "./components/stripe/Subscription";

import Api from "./service/api";
import FpnCalendar from "./components/components/calendars/FpnCalendar";
import * as Icon from "react-feather";
import PientryReports from "./components/reports/paymentsimport/pientry";
import FaqPage from "./components/components/faq/FaqPage";
import FpnMap from "./components/components/maps/FpnMap";


//firebase Auth
function AppWrapper() {

    useEffect(() => {
        const themeColor = localStorage.getItem('theme-color')
        const layout = localStorage.getItem('layout_version')
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${themeColor}.css`);
        document.body.classList.add(layout);
    }, []);

    const setUserData = (user) => {
        let formData = new FormData();
        formData.set('user', JSON.stringify(user));

        fetch(Api.getBackendAddress() + "app/ajuser/set", {
            method: 'POST',
            body: formData,
        })
    };

    const getMenuData = (user) => {
        let components = window.location.href.split('/');
        let parentComponent, component = '';
        if (components.length >= 6) {
            parentComponent = components[4];
            component = components[5];
        }
        let url = Api.getBackendAddress() + 'app/ui/menu/get?parentComponent=' + parentComponent + '&component=' + component + '&user=' + user.sub;
        fetch(url).then(res => res.json()).then(res => {
            let menuItems = res.menu;
            menuItems.map(item => {
                item.icon = Icon[item.iconName]
            });
            store.dispatch({type: 'SET_MENU', payload: menuItems, logo: res.logo});
        })
    }

    const {loading, user} = useAuth0();

    if (loading) {
        return <div>Loading...</div>;
    }

    setUserData(user);
    if (user) {
        getMenuData(user);
    }

    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>
                            <Fragment>
                                <App>
                                    {/* dashboard menu */}
                                    {/*<Route exact path={`${process.env.PUBLIC_URL}/`} component={Default}/>*/}
                                    {/*<Route exact path={`${process.env.PUBLIC_URL}/dashboard/default`}*/}
                                    {/*       component={Default}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/dashboard/ecommerce`}*/}
                                    {/*       component={Ecommerce}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/dashboard/university`}*/}
                                    {/*       component={University}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/dashboard/crypto`}*/}
                                    {/*       component={CryptoComponent}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/dashboard/project`} component={Project}/>*/}

                                    {/*/!* Sample page *!/*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/sample/samplepage`} component={Samplepage}/>*/}

                                    {/*/!* Pricing *!/*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/support-ticket/supportTicket`}*/}
                                    {/*       component={SupportTicket}/>*/}

                                    {/* Reports */}
                                    <PrivateRoute path={'/reports/paymentsimport/pientries'}
                                                  component={PientryReports}/>

                                    {/* Calendars */}
                                    <PrivateRoute path={`${process.env.PUBLIC_URL}/calendars/fpn`}
                                                  component={FpnCalendar}/>


                                    {/* Maps */}
                                    <PrivateRoute path={`${process.env.PUBLIC_URL}/maps/fpn`}
                                                  component={FpnMap}/>


                                    {/* FAQ */}
                                    <PrivateRoute path={`${process.env.PUBLIC_URL}/faq/:faqId`} component={FaqPage}/>

                                    {/* SUBSCRIBE */}
                                    <PrivateRoute path={`${process.env.PUBLIC_URL}/subscribe`} component={Wrapper}/>

                                    {/* Component */}
                                    <PrivateRoute
                                        path={`${process.env.PUBLIC_URL}/components/:groupName/:componentName/list`}
                                        component={componentList}/>
                                    <PrivateRoute
                                        path={`${process.env.PUBLIC_URL}/components/:groupName/:componentName/new`}
                                        component={componentNew}/>
                                    <PrivateRoute
                                        path={`${process.env.PUBLIC_URL}/components/:groupName/:componentName/view/:id`}
                                        component={componentView}/>
                                    <PrivateRoute
                                        path={`${process.env.PUBLIC_URL}/components/:groupName/:componentName/update/:id`}
                                        component={componentUpdate}/>

                                </App>
                            </Fragment>
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

export default AppWrapper;
