import React from 'react';
import CKEditors from "react-ckeditor-component";

const EditorField = (props) => {
    const {field, val, onDataUpdate} = props;

    const onDataChange = (evt) => {
        const newContent = evt.editor.getData();
        onDataUpdate(newContent, field.key)
    };

    return (
        <CKEditors
            activeclassName="p10"
            content={val}
            events={{
                "change": onDataChange
            }}
        />

    );
};

export default EditorField;
