import React, {Fragment, useRef, useState} from 'react';

import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, useElements, useStripe,} from '@stripe/react-stripe-js';
import ComponentTitle from "../components/componentTitle";
import {useAuth0} from "../../react-auth0-spa";

import Api from "../../service/api";
import WidgetCardHeader from "../components/widgets/helpers/widgetCardHeader";
import SubscriptionPlans from "./SubscriptionPlans";
import LoadingComponent from "../components/LoadingComponent";

const style = {
    base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
            color: "#aab7c4"
        }
    },
    invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
    }
};

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const Subscription = ({history} = this.props) => {
    const stripe = useStripe();
    const elements = useElements();
    const {user} = useAuth0();
    const [formData, setFormData] = useState({plan: null});
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);

    // console.log('user', user);
    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        // console.log('createPaymentMethod', error, paymentMethod);
        // user.sub
        if (!error) {
            let postFormData = new FormData();
            postFormData.set('paymentMethod', paymentMethod.id);
            postFormData.set('ajUserId', user.sub);
            postFormData.set('planId', formData.plan.id);

            fetch(Api.getBackendAddress() + 'app/stripe/subscribe', {
                body: postFormData,
                method: 'POST'
            }).then(res => res.json()).then(res => {
                setLoading(false);
                if (res.success === 1) {
                    window.location.href = '/components/stripe/stripe_my_subscription/list';
                } else {
                    alert('Error, please contact support');
                }
            });
        } else {
            setLoading(false);
            alert('Error, please contact support');
        }
    };

    const goBack = () => {
        history.goBack();
    };
    // const {components, history} = this.props;

    const myRef = useRef(null);
    const planSelected = (data, key) => {
        // console.log('planSelected', data, key);
        let formDataNew = {...formData};
        formDataNew[key] = data;
        setFormData(formDataNew);

        setTimeout(() => {
            scrollToRef(myRef)
        }, 300);
    };


    return (

        <Fragment>
            <div className="container-fluid">
                <ComponentTitle component={{title: 'Choose your plan\n'}} history={history}/>

                <SubscriptionPlans user={user} planSelected={planSelected} selected={formData.plan}/>

                {!!formData.plan &&
                (<div className={"row"} ref={myRef}>
                    <div className="col-12">

                        <div className="card">
                            <WidgetCardHeader title={"Enter card details"}/>
                            <div className="card-body">

                                <CardElement className={"MyCardElement"}/>


                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary mr-1" onClick={handleSubmit}>Subscribe</button>
                                <button className="btn btn-secondary" onClick={goBack}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>)
                }

            </div>
            {loading && <LoadingComponent/>}
        </Fragment>


    );
};

const stripePromise = loadStripe('pk_live_00oa4MSwPREzntFuD99BrHOZ00yyqvB2Rf');

const Wrapper = ({history} = this.props) => {
    return (<Elements stripe={stripePromise}><Subscription history={history}/></Elements>)
};


export default Wrapper;
