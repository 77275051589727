import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {componentsInstance} from '../../constant/components'
import ListTable from "./list/listTable";
import ComponentTitle from "./componentTitle";

class ComponentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showArchive: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        if (this.listRef) {
            this.listRef.resetPage();
        }
    }

    render() {
        const {groupName, componentName} = this.props.match.params;
        const {components, history} = this.props;

        let component = componentsInstance.getComponent(components, groupName, componentName);
        if (!component) {
            return <div></div>;
        }

        return (
            <Fragment>

                <ComponentTitle component={component} history={history} skipBack/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">

                                <div className="card-body pt-0">
                                    <ListTable ref={(ref) => this.listRef = ref} parentName={groupName}
                                               setCreatable={this.setCreatable}
                                               componentName={componentName} isView={true}
                                               />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(ComponentList);
