import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {componentsInstance} from '../../constant/components'
import ComponentTabs from "./view/componentTabs";
import {Link} from "react-router-dom";
import ComponentTitle from "./componentTitle";
import Api from "../../service/api";
import AjViewRow from "./form/ajviewrow";
import RpScriptWidget from "./widgets/RP/RpScriptWidget";
import FileWidget from "./widgets/FileWidget";
import GalleryWidget from "./widgets/GalleryWidget";
import TodoWidget from "./widgets/TodoWidget";
import {Auth0Context} from "../../react-auth0-spa";
import CommentsWidget from "./widgets/CommentsWidget";
import ActionWidget from "./widgets/ActionWidget";
import LoadingComponent from "./LoadingComponent";
import InvoiceTotalWidget from "./widgets/Invoices/InvoiceTotalWidget";
import RPStatsWidget from "./widgets/RP/RPStatsWidget";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import * as Icon from "react-feather";
import FPNServicesWidget from "./widgets/FPN/FPNServicesWidget";
import CompanyWidget from "./widgets/Company/CompanyWidget";

const Widgets = {
    RpScriptWidget: RpScriptWidget,
    FileWidget: FileWidget,
    GalleryWidget: GalleryWidget,
    TodoWidget: TodoWidget,
    CommentsWidget: CommentsWidget,
    ActionWidget: ActionWidget,
    InvoiceTotalWidget: InvoiceTotalWidget,
    RPStatsWidget: RPStatsWidget,
    FPNServicesWidget: FPNServicesWidget,
    CompanyWidget: CompanyWidget
};

const widgetInclude = (widgetBlock, formData, index, history) => {
    // component does exist
    if (typeof Widgets[widgetBlock.componentName] !== "undefined") {
        return React.createElement(Widgets[widgetBlock.componentName], {
            formData: formData,
            extraData: widgetBlock.extraData,
            editable: widgetBlock.editable,
            key: "widget-" + formData.id + "-" + index,
            history: history
        });
    }
    // component doesn't exist yet
    return React.createElement(
        () => <div key={"widget-" + formData['id'] + '-' + index}>The component {widgetBlock.componentName} has not been
            created yet.</div>
    );
};

const initialState = {
    fields: [],
    formData: {},
    recordName: '',
    tabs: [],
    creatable: false,
    updateable: false,
    viewActions: [],
    widgets: [],
    bottomWidgets: [],
    bottomExtraWidgets: [],
    loading: false,
    notFound: false
};

class ComponentView extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.fetchData();
    }

    fetchData = () => {
        this.setState({
            loading: true
        });
        const {groupName, componentName, id} = this.props.match.params;
        const {user} = this.context;

        Api.getViewData(groupName, componentName, id, user).then(res => {
            if (res.success === 1) {
                this.setState({
                    fields: res.fields,
                    formData: res.formData,
                    recordName: res.recordName,
                    tabs: res.tabs,
                    creatable: res.creatable,
                    updateable: res.updateable,
                    viewActions: res.actions,
                    widgets: res.widgets,
                    bottomWidgets: res.bottomWidgets,
                    bottomExtraWidgets: res.bottomExtraWidgets,
                    loading: false,
                    notFound: false
                });
            } else {
                this.setState({...initialState, notFound: true});
            }
        }).catch(e => {
            this.setState({
                loading: false
            });
        })
    };

    goBack = () => {
        this.props.history.goBack();
    };

    componentDidMount() {
        this.fetchData();
    }

    doDelete = () => {
        const {user} = this.context;

        if (!window.confirm('Are you sure?')) return false;

        const {groupName, componentName, id} = this.props.match.params;
        const {history} = this.props;

        let url = Api.getBackendAddress() + 'app/ui/data/save/delete?parent=' + groupName + '&component=' + componentName + '&id=' + id;

        let formData = new FormData();
        formData.append('ajUserId', user.sub);

        fetch(url, {
            method: 'POST',
            body: formData,
        }).then(res => res.json()).then(res => {
            if (res.success === 1) {
                history.goBack();
            }
        })
    };

    doArchive = () => {
        const {user} = this.context;

        if (!window.confirm('Are you sure?')) return false;

        const {groupName, componentName, id} = this.props.match.params;
        const {history} = this.props;

        let url = Api.getBackendAddress() + 'app/ui/data/save/archive?parent=' + groupName + '&component=' + componentName + '&id=' + id;

        let formData = new FormData();
        formData.append('ajUserId', user.sub);

        fetch(url, {
            method: 'POST',
            body: formData,
        }).then(res => res.json()).then(res => {
            if (res.success === 1) {
                window.location.reload();
            }
        })
    };

    doRestore = () => {
        const {user} = this.context;

        if (!window.confirm('Are you sure?')) return false;

        const {groupName, componentName, id} = this.props.match.params;
        const {history} = this.props;

        let url = Api.getBackendAddress() + 'app/ui/data/save/restore?parent=' + groupName + '&component=' + componentName + '&id=' + id;

        let formData = new FormData();
        formData.append('ajUserId', user.sub);

        fetch(url, {
            method: 'POST',
            body: formData,
        }).then(res => res.json()).then(res => {
            if (res.success === 1) {
                window.location.reload();
            }
        })
    };

    doExport = () => {

    };

    render() {
        const {groupName, componentName, id} = this.props.match.params;
        const {components, history} = this.props;
        const {formData, fields, updateable, creatable, viewActions, notFound} = this.state;

        let component = componentsInstance.getComponent(components, groupName, componentName);
        if (!component) {
            return <div></div>;
        }

        return (
            <Fragment>
                <div className="container-fluid">
                    <ComponentTitle component={component} history={history} rightActions={
                        notFound ? [] :
                            [
                                updateable ?
                                    <Link className="btn btn-success btn-air-success btn-pill btn-sm"
                                          key={"tlbr-update-" + formData.id}
                                          to={`${process.env.PUBLIC_URL}/components/${groupName}/${componentName}/update/${id}`}>Update</Link>
                                    : null,

                                creatable ?
                                    <Link
                                        className={"btn btn-info btn-pill btn-air-info btn-sm ml-2"}
                                        key={"tlbr-copy-" + formData.id}
                                        title={"Copy"}
                                        to={`${process.env.PUBLIC_URL}/components/${groupName}/${componentName}/new?copyFrom=${id}`}><Icon.Copy
                                        size={14}/></Link>
                                    : null,


                                <UncontrolledDropdown key={"tlbr-export-" + formData.id}
                                                      style={{display: 'inline-block'}}
                                                      title={"Export"}>
                                    <DropdownToggle caret
                                                    className="btn btn-info btn-air-info btn-pill btn-sm ml-2">
                                        <Icon.Download size={14}/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>PDF</DropdownItem>
                                        <DropdownItem>XLS</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                ,

                                updateable ?

                                    <UncontrolledDropdown key={"tlbr-delete-" + formData.id}
                                                          style={{display: 'inline-block'}}
                                                          title={"Archive or Delete"}>
                                        <DropdownToggle caret
                                                        className="btn btn-danger btn-air-danger btn-pill btn-sm ml-2">
                                            <Icon.Trash2 size={14}/>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {formData.archived ?
                                                <DropdownItem onClick={this.doRestore}>Restore</DropdownItem> :
                                                <DropdownItem onClick={this.doArchive}>Archive</DropdownItem>}

                                            <DropdownItem onClick={this.doDelete}>Delete</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    : null
                                //
                            ]
                    }
                                    extraActions={viewActions}
                                    archived={formData.archived}
                    />

                    {notFound ?
                        <div className="alert alert-danger fade show" role="alert">Element not
                            found.</div> :

                        <div className="row">
                            <div className="col-8">
                                <div className="card">
                                    <div className="card-body pt-4">

                                        <div className="row">
                                            <div className="col-12">
                                                <form className="theme-form">

                                                    {fields.map((field, index) => {
                                                        if (field.type === 'skip') {
                                                            return '';
                                                        }
                                                        return <AjViewRow key={"field-view-row-" + field.key}
                                                                          field={field}
                                                                          formData={formData}
                                                                          groupName={groupName}
                                                                          editable={updateable}
                                                                          componentName={componentName}
                                                                          index={index}/>;
                                                    })}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {this.state.bottomWidgets.length > 0 && this.state.bottomWidgets.map((item, index) => widgetInclude(item, formData, index, history))}

                                {this.state.tabs.length > 0 &&
                                <ComponentTabs tabs={this.state.tabs}/>
                                }

                                {this.state.bottomExtraWidgets.length > 0 && this.state.bottomExtraWidgets.map((item, index) => widgetInclude(item, formData, index, history))}
                            </div>
                            <div className={"col-4"}>

                                {/*<FileWidget parentTag={groupName + "-" + componentName + "-" + id}/>*/}


                                {/*<div className={"card"}>*/}
                                {/*    <div className={"card-body"}>*/}
                                {/*        <div className="number-widgets">*/}
                                {/*            <div className="media">*/}
                                {/*                <div className="media-body align-self-center"><h6 className="mb-0">Payment*/}
                                {/*                    Status</h6></div>*/}
                                {/*                <div className="radial-bar radial-bar-95 radial-bar-primary"*/}
                                {/*                     data-label="95%"></div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}


                                {/*<div className="card">*/}
                                {/*    <div className="card-body">*/}
                                {/*        <div className="media"><h5 className="mb-0">Overdue</h5></div>*/}
                                {/*        <div className="project-widgets text-center"><h1*/}
                                {/*            className="font-primary counter">7</h1><h6 className="mb-0">Tasks</h6></div>*/}
                                {/*    </div>*/}
                                {/*    <div className="card-footer project-footer"><h6 className="mb-0">Task Solved:<span*/}
                                {/*        className="counter ml-1"><span>4</span></span></h6></div>*/}
                                {/*</div>*/}

                                {this.state.widgets.map((item, index) => widgetInclude(item, formData, index, history))}

                            </div>
                        </div>
                    }
                </div>
                {this.state.loading && <LoadingComponent/>}
            </Fragment>
        )
            ;
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(ComponentView);
