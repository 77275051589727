import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {Link} from "react-router-dom";
import ListExtraActions from "../listExtraActions";

class ListActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: ''
        };
    }

    resetPage = () => {
        this.setState({
            search: ''
        });
    }

    onSearchChange = (newSearch) => {
        this.setState({
            search: newSearch
        });
    };

    onSearchDone = (e) => {
        e.preventDefault();
        this.props.onSearchChange(this.state.search);
    };

    render() {
        const {component, extraQuery, size, creatable, tableActions, title, searchable} = this.props;
        const {groupName, componentName} = component;

        let extraQueryStr = extraQuery ? JSON.stringify(extraQuery) : '';

        return (
            <Fragment>
                <div className="row" style={{alignItems: 'center'}}>
                    {title && <div className={"col-auto"}>
                        <h5 className={"p-0 m-0"}>{title}</h5>
                    </div>}
                    <div className={"col"}>
                        {creatable &&
                        <Link
                            className={"btn btn-primary btn-pill btn-air-primary" + (size === 'small' ? " btn-sm" : "")}
                            to={`${process.env.PUBLIC_URL}/components/${groupName}/${componentName}/new?extraQuery=${extraQueryStr}`}>New</Link>
                        }

                        <ListExtraActions actions={tableActions} size={size}/>


                        {/*<button*/}
                        {/*    className={"btn btn-light btn-pill btn-air-light ml-2" + (size === 'small' ? " btn-sm" : "")}>Export*/}
                        {/*</button>*/}
                    </div>
                    {searchable &&
                    <div className="col-auto">
                        <form className="search-form contact-search" onSubmit={this.onSearchDone}>
                            <div className="form-group mb-0">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="search"
                                    defaultValue={this.state.search}
                                    onChange={(e) => this.onSearchChange(e.target.value)}
                                />
                            </div>
                        </form>
                    </div>
                    }

                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(ListActions);
