import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Api from "../../../service/api";
import start_conversion from '../../../assets/images/start-conversion.jpg';
import moment from 'moment';
import {Auth0Context} from "../../../react-auth0-spa";

// var images = require.context('../../../assets/images', true);

class CommentsWidget extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);
        this.state = {
            messageInput: '',
            data: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const {extraData} = this.props;

        fetch(Api.getBackendAddress() + 'app/comments/get-comments/' + extraData.parentTag).then(res => res.json()).then(res => {
            this.setState({data: res.data});
        });
    }

    handleMessageChange = (e) => {
        this.setState({
            messageInput: e.target.value
        })
    };

    handleMessagePress = (e) => {
        // e.preventDefault();
        const {user} = this.context;
        const {extraData} = this.props;

        const formData = new FormData();
        formData.append("parentTag", extraData.parentTag);
        formData.append("description", this.state.messageInput);
        formData.append('ajUserId', user.sub);

        this.setState({
            messageInput: ''
        });

        fetch(Api.getBackendAddress() + 'app/comments/create', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                this.fetchData();
            })
            .catch(error => {
                console.error(error)
            })
    };

    render() {
        const {user} = this.context;
        const {messageInput} = this.state;

        return (
            <Fragment>

                <div className="card">
                    <div className={"card-header pb-2 pt-4"}>
                        <h6>Comments</h6>
                    </div>
                    <div className="card-body p-0">
                        <div className="row chat-box mr-0 ml-0">
                            <div className="col pr-0 pl-0 chat-right-aside">
                                <div className="chat">
                                    {/*<div className="chat-header clearfix">*/}
                                    {/*    /!*<img src={dynamicImage(selectedUser.thumb)} className="rounded-circle" alt=""/>*!/*/}
                                    {/*    <div className="about">*/}
                                    {/*        <div className="name">*/}
                                    {/*            {"name"}*/}
                                    {/*        </div>*/}
                                    {/*        <div className="status digits">*/}
                                    {/*            {1 === 1 ? 'online' : 'selectedUser.lastSeenDate'}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <ul className="list-inline float-left float-sm-right chat-menu-icons">*/}
                                    {/*        <li className="list-inline-item"><a href="#javascript"><i*/}
                                    {/*            className="icon-search"></i></a></li>*/}
                                    {/*        <li className="list-inline-item"><a href="#javascript"><i*/}
                                    {/*            className="icon-clip"></i></a></li>*/}
                                    {/*        <li className="list-inline-item"><a href="#javascript"><i*/}
                                    {/*            className="icon-headphone-alt"></i></a></li>*/}
                                    {/*        <li className="list-inline-item"><a href="#javascript"><i*/}
                                    {/*            className="icon-video-camera"></i></a></li>*/}
                                    {/*        <li className="list-inline-item toogle-bar"*/}
                                    {/*            // onClick={() => chatMenuToggle()}*/}
                                    {/*        >*/}
                                    {/*            <a href="#javascript"><i*/}
                                    {/*                className="icon-menu"></i></a></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}

                                    <div className="chat-history chat-msg-box custom-scrollbar"
                                         style={{minHeight: 100, height: 'auto', maxHeight: 400}}>
                                        <ul>
                                            {this.state.data.length > 0 ? this.state.data.map((item, index) => {
                                                    // const participators = allMembers.find(x => x.id == item.sender);
                                                    return (
                                                        <li key={index} className="clearfix">
                                                            <div
                                                                className={`message my-message ${item.userId !== user.sub ? '' : 'float-right'}`}>
                                                                {item.picture && <img src={item.picture}
                                                                                      className={`rounded-circle ${item.userId !== user.sub ? 'float-left' : 'float-right'} chat-user-img img-30`}
                                                                                      alt=""/>}

                                                                <div className="d-flex w-100 justify-content-between">
                                                                    <strong>{item.userName}</strong>
                                                                    <small>{item.time ? moment(item.time).fromNow() : ''}</small>
                                                                </div>
                                                                {/*<div className="message-data text-right">*/}
                                                                {/*    <span*/}
                                                                {/*        className="message-data-time">{item.time ? moment(item.time).fromNow() : ''}</span>*/}
                                                                {/*</div>*/}
                                                                {item.description}
                                                            </div>
                                                        </li>
                                                    );
                                                }) :
                                                <div>
                                                    {/*<img className="img-fluid" src={start_conversion} alt="start conversion "/>*/}
                                                </div>
                                            }
                                        </ul>

                                    </div>
                                    <div className="chat-message clearfix">
                                        <div className="row">
                                            <div className="col-xl-12 d-flex">
                                                {/*<div className="smiley-box bg-primary">*/}
                                                {/*    <div className="picker"><img*/}
                                                {/*        src={require('../../../assets/images/smiley.png')}*/}
                                                {/*        className="" alt=""/></div>*/}
                                                {/*</div>*/}
                                                <div className="input-group text-box">
                                                    <input
                                                        type="text"
                                                        className="form-control input-txt-bx"
                                                        placeholder="Type a message......"
                                                        value={messageInput}
                                                        // onKeyPress={this.handleMessagePress}
                                                        onChange={this.handleMessageChange}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="button"
                                                                onClick={this.handleMessagePress}>SEND
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(CommentsWidget);
