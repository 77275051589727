import React from 'react';
import DateField from "./fields/date";
import ManytooneField from "./fields/manytoone";
import OnetomanyField from "./fields/onetomany";
import Select from 'react-select';
import AddressField from "./fields/AddressField";
import EditorField from "./fields/EditorField";

class AjFormRowContent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {field, onDataUpdate, formData} = this.props;

        const fieldId = "field" + field.key;
        let inputType = 'text';
        if (field.type === 'file') {
            inputType = 'file';
        }

        if (field.type === 'section') {
            return '';
        }

        let required = field.required;

        if (field.type === 'one-to-many') {
            return <OnetomanyField field={field} onDataUpdate={onDataUpdate} formData={formData} key={'row-' + fieldId}
                                   isView={false}/>;
        }

        let inputPlace = <input className="form-control" id={fieldId}
                                type={inputType}
                                placeholder={field.placeholder}
                                value={formData[field.key]}
                                onChange={(e) => onDataUpdate(e.target.value, field.key)}
                                required={field.required}
        />;
        if (field.type === 'date') {
            inputPlace =
                <DateField val={formData[field.key]} field={field}
                           onDataUpdate={onDataUpdate}/>
        } else if (field.type === 'file') {
            inputPlace =
                <input className="form-control" id={fieldId}
                       type={inputType}
                       placeholder={field.placeholder}
                       onChange={(e) => onDataUpdate(e.target.value, field.key)}/>
        } else if (field.type === 'text' || field.type === 'DoctrineArray') {
            inputPlace =
                <textarea className="form-control" id={fieldId}
                          placeholder={field.placeholder}
                          rows={5}
                          value={formData[field.key]}
                          onChange={(e) => onDataUpdate(e.target.value, field.key)}/>
        } else if (field.type === 'many-to-one') {
            inputPlace = <ManytooneField val={formData[field.key]} field={field}
                                         onDataUpdate={onDataUpdate}/>
        } else if (field.type === 'enum') {
            inputPlace = <Select
                className="basic-single"
                classNamePrefix="select"
                formatOptionLabel={(selected) => selected.name}
                onChange={(selected) => onDataUpdate(selected, field.key)}
                name="color"
                value={formData[field.key]}
                options={field.values}
                isOptionSelected={(selected) => {
                    return 'key' in selected && field.key in formData && 'key' in formData[field.key] &&
                        selected.key === formData[field.key].key
                }}
            />;
        } else if (field.type === 'bool') {
            inputPlace = <Select
                className="basic-single"
                classNamePrefix="select"
                formatOptionLabel={(selected) => selected.name}
                onChange={(selected) => onDataUpdate(selected, field.key)}
                name="color"
                value={formData[field.key]}
                options={[{key: 0, name: 'No'}, {key: 1, name: 'Yes'}]}
                isOptionSelected={(selected) => {
                    return selected.key === formData[field.key].key
                }}
            />;
        } else if (field.type === 'address') {
            inputPlace = <AddressField
                val={formData[field.key]}
                field={field}
                onDataUpdate={onDataUpdate}
                fieldId={fieldId}
            />;
        } else if (field.type === 'ckeditor') {
            inputPlace = <EditorField
                val={formData[field.key]}
                field={field}
                onDataUpdate={onDataUpdate}
                fieldId={fieldId}
            />;
        }
        return inputPlace;
    }

};

export default AjFormRowContent;
