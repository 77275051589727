import React from 'react';

import moment from "moment";

const DateColumn = (props) => {
    const {column, val, isView} = props;
    return val ? (moment(val).format('YYYY-MM-DD')) : '';
};

export default DateColumn;
