import React from 'react';

const AddressColumn = (props) => {
    const {column, val} = props;

    let addressToShow = val;
    try {
        let jsonAddress = JSON.parse(val);
        addressToShow = jsonAddress.formattedAddress;
    } catch (e) {
        // just ignore
    }

    return addressToShow;
};

export default AddressColumn;
