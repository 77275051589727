import {SET_MENU} from "../constant/actionTypes";

const initial_state = {
    menu: [],
    logo: '',
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case SET_MENU:
            return {...state, menu: action.payload, logo: action.logo};

        default:
            return {...state};
    }
}
