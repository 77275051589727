import React from "react";
import ManytooneField from "../../form/fields/manytoone";
import Api from "../../../../service/api";

const AddServiceAction = ({addData, user} = this.props) => {
    const addServiceData = (service) => {
        Api.getUpdateData('service', 'service', service.id, user).then(res => {
            let item = {
                name: res.formData.name,
                code: res.formData.code,
                price: res.formData.price,
                service: service,
                quantity: 1
            };
            addData(item);
        });
    }
    return <div className={"col-4"}>
        <ManytooneField
            val={null}
            field={{
                selectParent: 'service',
                selectComponent: 'service',
                key: 'new'
            }}
            placeholder={"Search for services"}
            onDataUpdate={(val, key) => {
                addServiceData(val);
            }}
        />
    </div>
}
export default AddServiceAction;
