import React, {Fragment, useState} from 'react';
import man from '../../../assets/images/dashboard/user.png';
import {LogOut, User} from 'react-feather';
import {useAuth0} from "../../../react-auth0-spa";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import ChangePasswordPopup from "./ChangePasswordPopup";

const UserMenu = () => {
    const {user, logout} = useAuth0();
    const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
    const togglePasswordModal = () => setPasswordModalOpen(!isPasswordModalOpen);

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
                         src={user ? user.picture : man} alt="header-user"/>
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><a onClick={togglePasswordModal}><User/>Change password</a></li>
                    {/*<li><a href="#javascript"><Mail />Inbox</a></li>*/}
                    {/*<li><a href="#javascript"><Lock />Lock Screen</a></li>*/}
                    {/*<li><a href="#javascript"><Settings />Settings</a></li>*/}
                    <li><a onClick={() => logout()}><LogOut/> Log out</a></li>
                </ul>
            </li>

            <Modal isOpen={isPasswordModalOpen} toggle={togglePasswordModal} size="lg">
                <ModalHeader toggle={togglePasswordModal}>Change password</ModalHeader>
                <ModalBody>
                    <ChangePasswordPopup toggle={togglePasswordModal}/>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};


export default UserMenu;
