import React from 'react';
import {Link} from "react-router-dom";
import Column from "./columns/column";


class AjTbody extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {data, columns, parentName, componentName, isView, onDataUpdate, onDataRemove, isField, viewable} = this.props;

        return (
            <tbody>
            {data.map((row, index) => {


                return <tr key={'row-' + row.id}>
                    <th>
                        {isField ? (index + 1) : viewable ? <Link
                            to={`${process.env.PUBLIC_URL}/components/${parentName}/${componentName}/view/${row.id}`}>{index + 1}</Link> : (index + 1)
                        }
                    </th>
                    {columns.map((item, columnIndex) => {
                        let value = row[item.key];

                        let className = [];
                        if (item.type === 'float') {
                            className.push('text-right');
                        } else if (item.type === 'date') {
                            className.push('text-center');
                        }
                        if (item.extraClassName) {
                            className.push(item.extraClassName);
                        }

                        return <td className={className.join(' ')} key={"col-" + columnIndex + "-" + item.key}>
                            <Column rowData={row} column={item} val={value}
                                    onDataUpdate={(fieldValue) => onDataUpdate(index, item.key, fieldValue)}
                                    isForm={false}
                                    groupName={parentName}
                                    componentName={componentName}
                                    id={row.id}
                                    key={"column-" + columnIndex + "-" + index} isView={isView}
                                    editable={false}/>
                        </td>;
                    })}
                    {isField && !isView && <td className={"text-right"}>
                        <button className="btn btn-danger btn-air-danger btn-pill btn-sm ml-2"
                                onClick={() => onDataRemove(row.id)}>
                            <i className="fa fa-minus-square"></i>
                        </button>
                    </td>}

                </tr>;
            })}

            </tbody>
        );
    }
};

export default AjTbody;
