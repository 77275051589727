import React from 'react';
import parse from 'html-react-parser';

const EditorColumn = (props) => {
    const {column, val} = props;


    return parse(val);
};

export default EditorColumn;
