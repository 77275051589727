import React, {Fragment} from 'react';
import ListExtraActions from "./listExtraActions";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';

class ComponentTitle extends React.Component {

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const {component, skipBack, rightActions, extraActions, archived} = this.props;

        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="page-header">

                        <div className={"row"}>
                            <div className="col">
                                <div className="page-header-left" style={{alignItems: 'center'}}>
                                    <div className={"row"}>
                                        {!skipBack &&
                                        <div className={"col-auto"}>
                                            <button className="btn btn-light btn-air-light btn-pill btn-sm mr-4"
                                                    onClick={this.goBack}>
                                                <i className="fa fa-arrow-left"></i>
                                            </button>
                                        </div>
                                        }
                                        <div className={"col"}>
                                            <h5 className={"mb-0 pb-0"}>{component.title}</h5>
                                        </div>
                                        {archived &&
                                        <div className={"col-auto"}>
                                            <span className="badge badge-pill badge-danger ml-4">Archived</span></div>}
                                    </div>
                                </div>


                            </div>

                            <div className={"col-auto"}>
                                {extraActions && <ListExtraActions actions={extraActions} size={"small"}/>}
                                {rightActions}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ComponentTitle;
