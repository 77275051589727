import React, {Fragment} from 'react';
import LinesTable from "../../list/linesTable";

const OnetomanyField = (props) => {
    const {field, onDataUpdate, formData, isView} = props;

    const mappedBy = field.mappedBy;
    let extraQuery = {};

    const params = new URLSearchParams(window.location.search);
    if (params.get('copyFrom')) {
        extraQuery[mappedBy] = params.get('copyFrom');
        extraQuery['__copy'] = 1;
    } else {
        extraQuery[mappedBy] = formData['id'];
    }

    const onDataUpdateFunc = (updatedData, index, key) => onDataUpdate ? onDataUpdate(updatedData, field.key, index, key) : {};

    const data = (
        <Fragment>
            <LinesTable parentName={field.selectParent}
                        componentName={field.selectComponent}
                        onDataUpdate={onDataUpdateFunc}
                        extraQuery={extraQuery}
                        isView={isView}
                        isField
                        actions={field.actions}
            />
        </Fragment>);

    return data;
};

export default OnetomanyField;
