class ApiService {
    getBackendAddress() {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return 'http://127.0.0.1:8000/';
        }
        return 'https://app.blackfomo.com/';
    }

    getTableData(parentName, componentName, extraQuery, hideColumns, page, pageSize, search, user, sort, showArchive, addColumns) {
        let url = this.getBackendAddress() + 'app/ui/data/get/table?';
        url += 'page=' + page;
        url += '&pageSize=' + (pageSize ? pageSize : 20);
        url += '&parent=' + parentName;
        url += '&component=' + componentName;
        url += '&extraQuery=' + JSON.stringify(extraQuery ? extraQuery : {});
        url += '&hideColumns=' + JSON.stringify(hideColumns ? hideColumns : {});
        url += '&addColumns=' + JSON.stringify(addColumns ? addColumns : []);
        url += "&ajUserId=" + user.sub;
        url += '&search=' + encodeURIComponent(search)
        if (sort) {
            url += '&sort=' + JSON.stringify(sort)
        }
        if (showArchive) {
            url += '&showArchive=1';
        }

        return fetch(url).then(res => res.json());
    }

    getViewData(parentName, componentName, id, user) {
        let url = this.getBackendAddress() + 'app/ui/data/get/view?';
        url += 'parent=' + parentName;
        url += '&component=' + componentName;
        url += '&id=' + id;
        url += '&ajUserId=' + user.sub;

        return fetch(url).then(res => res.json());
    }

    getNewData(parentName, componentName, copyFrom, user) {
        let url = this.getBackendAddress() + 'app/ui/data/get/create?';
        url += 'parent=' + parentName;
        url += '&component=' + componentName;
        url += '&ajUserId=' + user.sub;
        if (copyFrom) {
            url += '&copyFrom=' + copyFrom;
        }

        return fetch(url).then(res => res.json())
    }

    getUpdateData(parentName, componentName, id, user) {
        let url = Api.getBackendAddress() + 'app/ui/data/get/update?';
        url += 'parent=' + parentName;
        url += '&component=' + componentName;
        url += '&id=' + id;
        url += '&ajUserId=' + user.sub;

        return fetch(url).then(res => res.json())
    }
}

const Api = new ApiService();
export default Api;
