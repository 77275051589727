import React from 'react';
import OnetomanyField from "./fields/onetomany";
import Column from "../list/body/columns/column";
const nl2br = require('react-nl2br');

class AjViewRow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {field, formData, groupName, componentName, editable, index} = this.props;
        let value = '';

        if (field.type === 'section') {

        } else {
            value = formData[field.key];
        }

        const showColon = field.type !== 'section' && field.type !== 'one-to-many';

        if (field.type === 'one-to-many') {
            return <div className="form-group row" key={'row' + field.key}>
                <label className={"col-form-label pt-0 col-12 f-w-600 mt-4"}>{field.title}{showColon && ":"}</label>
                <div className={"col-12"}>
                    <OnetomanyField field={field}
                                    onDataUpdate={null}
                                    formData={formData}
                                    isView={true}/>
                </div>
            </div>
        }

        // return <tr key={'row-' + field.key}>
        //     <td>{field.title}:</td>
        //     <Column column={field} val={value} isView={true} isForm={true}/>
        // </tr>;

        const fieldId = "field" + field.key;
        let labelSize = 'col-4';
        let inputSize = 'col-8';

        if (field.afterFieldText) {
            inputSize = 'col-auto';
        }

        if (field.type === 'one-to-many') {
            labelSize = 'col-12';
            inputSize = 'col-12';
        }

        let extraClass = [];
        if (field.type === 'section') {
            extraClass.push("f-w-600");
            if (index !== 0) {
                extraClass.push("mt-4");
            }

            labelSize = 'col-11';
            inputSize = 'col-1';
        }

        return <div className="form-group row" key={'row' + field.key}>
            <label className={"col-form-label pt-0 " + labelSize + " " + extraClass.join(" ")}
                   htmlFor={fieldId}>{field.title}{showColon && ":"}</label>
            <div className={inputSize}>
                <Column column={field} val={value} isView={true} isForm={true}
                        groupName={groupName}
                        componentName={componentName}
                        id={formData.id}
                        editable={editable}
                />
            </div>
            {!!field.afterViewFieldText &&
            <div className={'col-2'}>
                <small className="form-text text-muted">{nl2br(field.afterViewFieldText)}</small>
            </div>
            }
            {field.description &&
            <small className="form-text text-muted col-12">{nl2br(field.description)}</small>}
        </div>
    }

};

export default AjViewRow;
