import React, {Component, Fragment} from "react";


import ComponentTitle from "../componentTitle";
import Api from "../../../service/api";
import {Auth0Context} from "../../../react-auth0-spa";
import {Collapse} from 'reactstrap';
import {HelpCircle} from 'react-feather';
import parse from 'html-react-parser';

class FaqPage extends Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            title: '',
            opened: 0
        };
    }


    fetchData = () => {
        const {faqId} = this.props.match.params;
        const {user} = this.context;

        let url = Api.getBackendAddress() + 'app/faq/get/' + faqId + '?ajUserId=' + user.sub;
        fetch(url).then(res => res.json()).then(res => {
            if (res.success === 1) {
                this.setState({
                    title: res.title,
                    data: res.data
                })
            }
        })
    };

    componentDidMount() {
        this.fetchData();
    }

    setOpened = (index) => {
        this.setState({
            opened: index
        });
    };

    render() {
        const {history} = this.props;
        const {title, data} = this.state;

        return (
            <Fragment>
                <ComponentTitle component={{title: title}} history={history} skipBack/>
                <div className="container-fluid">
                    <div className="faq-wrap">
                        <div className="row default-according style-1 faq-accordion">
                            <div className="col-12">

                                {data.map((item, index) => {
                                    const isOpened = this.state.opened === index;
                                    return (
                                        <div className="card">
                                            <div className="card-header">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed pl-0"
                                                            onClick={() => this.setOpened(index)}
                                                            data-toggle="collapse" data-target="#collapseicon5"
                                                            aria-expanded={!isOpened}>
                                                        <HelpCircle/> {item.title}
                                                    </button>
                                                </h5>
                                            </div>
                                            <Collapse isOpen={isOpened}>
                                                <div className="card-body">{parse(item.answer)}</div>
                                            </Collapse>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>


        );
    }
}

export default FaqPage;
