import React, {useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import Api from "../../../../../service/api";
import {useAuth0} from "../../../../../react-auth0-spa";

const AjStatusColumn = (props) => {
    const {column, val, isForm, groupName, componentName, id, editable} = props;
    // console.log('props', props);
    const {values} = column;
    let selectedVal = values.filter((item) => item.key === val);
    selectedVal = selectedVal.length > 0 ? selectedVal[0] : null;

    const {user} = useAuth0();
    const [isDropdownOpen, setDropDownValue] = useState(false);

    if (!(selectedVal && selectedVal.title)) {
        return '';
    }

    const setStatus = (key) => {
        if (!window.confirm('Are you sure?')) return false;

        let url = Api.getBackendAddress() + 'app/ui/data/save/changeStatus?parent=' + groupName + '&component=' + componentName + '&id=' + id;

        let formData = new FormData();
        formData.append('ajUserId', user.sub);
        formData.append('status', key);
        formData.append('statusKey', column.key);

        fetch(url, {
            method: 'POST',
            body: formData,
        }).then(res => res.json()).then(res => {
            if (res.success === 1) {
                window.location.reload();
            }
        })
    };

    const statusComponent =
        <span className={"badge badge-pill badge-" + selectedVal.color}>{selectedVal.title}</span>;

    if (editable) {
        return (
            <div className="dropdown">
                <div className="btn-group mb-0">
                    <Dropdown direction="down" isOpen={isDropdownOpen} size="sm" toggle={() => {
                        setDropDownValue(!isDropdownOpen);
                    }}>
                        <DropdownToggle caret color={selectedVal.color}>
                            {selectedVal.title}
                        </DropdownToggle>
                        <DropdownMenu>
                            {values.map((item, index) => {
                                return (<DropdownItem
                                    key={"button-" + item.key + "-index"}
                                    onClick={() => setStatus(item.key)}>{item.title}</DropdownItem>)
                            })}

                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>);
    }

    return statusComponent;
};

export default AjStatusColumn;
