import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Api from "../../../../service/api";
import {Auth0Context} from "../../../../react-auth0-spa";
import {Link} from "react-router-dom";


class CompanyWidget extends React.Component {
    static contextType = Auth0Context;

    constructor(props) {
        super(props);

        this.state = {
            companyData: {}
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        const {extraData, formData} = this.props;
        const {key} = extraData;

        if (!formData[key]) {
            return;
        }
        const {user} = this.context;
        Api.getViewData('crm', 'companies', formData[key].id, user).then(res => {
            if (res.success === 1) {
                this.setState({
                    companyData: res.formData
                });
            } else {
                // this.setState({...initialState, notFound: true});
            }
        }).catch(e => {
            this.setState({
                loading: false
            });
        });
    }

    newSms = () => {

    }

    newEmail = () => {

    }

    render() {
        const {extraData, formData} = this.props;
        const {title, key} = extraData;
        const {companyData} = this.state;

        if (!companyData) {
            return <Fragment/>;
        }

        return (
            <Fragment>
                <div className={"card"}>
                    <div className={"card-header"}>
                        <h6>{companyData.name}</h6>
                    </div>
                    <div className={"card-body"}>
                        {companyData.email &&
                        <div className={"row mt-2 vertical-center"}>
                            <div className={"col-1"}>
                                <i className="fa fa-envelope mr-2"></i>
                            </div>
                            <div className={"col"}>
                                {companyData.email}
                            </div>
                            <div className={"col-3"}>
                                <button className={"btn"} onClick={this.newEmail} title="New email"><i className="icofont icofont-ui-message"></i></button>
                            </div>
                        </div>
                        }
                        {companyData.phone &&
                        <div className={"row mt-2 vertical-center"}>
                            <div className={"col-1"}>
                                <i className="fa fa-phone mr-2"></i>
                            </div>
                            <div className={"col"}>
                                {companyData.phone}
                            </div>
                            <div className={"col-3"}>
                                <button className={"btn"} onClick={this.newSms} title={"New sms"}><i className="icofont icofont-ui-messaging"></i></button>
                            </div>

                        </div>
                        }
                    </div>
                    <div className={"card-footer"}>
                        <Link className="btn btn-info btn-air-info btn-pill btn-sm"
                              key={"company-" + formData.id}
                              to={`${process.env.PUBLIC_URL}/components/crm/companies/view/${companyData.id}`}>View</Link>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(CompanyWidget);
