import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Dropzone from 'react-dropzone'
import Api from "../../../service/api";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

class GalleryWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            previewFile: null,

            photoIndex: 0,
            isOpen: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const {extraData} = this.props;

        fetch(Api.getBackendAddress() + 'app/files/get-files/' + extraData.parentTag).then(res => res.json()).then(res => {
            this.setState({data: res.data});
        });
    }

    onAcceptFiles = (acceptedFiles) => {
        const {extraData} = this.props;

        const files = acceptedFiles;
        const formData = new FormData();
        formData.append("parentTag", extraData.parentTag);
        for (let i = 0; i < files.length; i++) {
            let fileData = files[i];
            formData.append('file_' + i, fileData);
        }

        fetch(Api.getBackendAddress() + 'app/files/upload', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                this.fetchData();
            })
            .catch(error => {
                console.error(error)
            })
    };

    deleteFile = (item) => {
        if (!window.confirm('Are you sure?')) return false;

        fetch(Api.getBackendAddress() + 'app/files/delete-file/' + item.id).then(res => res.json()).then(res => {
            this.fetchData();
        });
    };

    getFileLink = (item) => {
        return Api.getBackendAddress() + 'app/files/get-file-binary/' + item.id;
    };

    render() {
        const {data, photoIndex, isOpen} = this.state;
        const {editable} = this.props;
        return (
            <Fragment>

                <Dropzone onDrop={this.onAcceptFiles}>
                    {({getRootProps, getInputProps}) => (
                        <div className={"card"} key={"drop-card"}  {...getRootProps()}>
                            <div className={"card-header pb-2 pt-4"}>
                                <h6>Gallery</h6>
                            </div>
                            <div className={"card-body my-gallery row gallery-with-description"}>
                                {data.map((item, index) => {
                                    return <figure className="col-xl-4 col-sm-6">
                                        <img
                                            src={this.getFileLink(item)}
                                            alt="Gallery"
                                            className="img-thumbnail"
                                            onClick={() =>
                                                this.setState({photoIndex: index, isOpen: true})
                                            }
                                        />
                                        <div className="caption">
                                            <h4>{item.name}</h4>
                                            {editable && <p>
                                                <button className="btn btn-danger btn-air-danger btn-pill btn-sm"
                                                        key={"delete"}
                                                        onClick={() => this.deleteFile(item)}>Delete
                                                </button>
                                            </p>}
                                        </div>
                                    </figure>
                                })}
                            </div>
                        </div>
                    )}
                </Dropzone>


                {isOpen && (
                    <Lightbox
                        mainSrc={this.getFileLink(data[photoIndex])}
                        nextSrc={this.getFileLink(data[(photoIndex + 1) % data.length])}
                        prevSrc={this.getFileLink(data[(photoIndex + data.length - 1) % data.length])}
                        imageTitle={photoIndex + 1 + "/" + data.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + data.length - 1) % data.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % data.length
                            })
                        }
                    />
                )}

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        components: state.Components.components
    }
};

export default connect(mapStateToProps)(GalleryWidget);
