import React, {Fragment} from 'react';
import ListTable from "../list/listTable";

class ComponentTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        };
    }

    setActiveTab = (tab) => {
        this.setState({
            activeTab: tab
        });
    };

    render() {
        const {tabs} = this.props;
        const {activeTab} = this.state;

        if (!tabs) {
            return <Fragment/>;
        }

        return (
            <Fragment>
                {tabs.map((tab, index) => {
                    let component = {
                        groupName: tab.parent,
                        componentName: tab.component
                    };

                    return (
                        <div className={"row"} key={"tab-" + tab.parent + "-" + tab.component}>
                            <div className="col">
                                <div className="card">
                                    <div className="card-body pt-2">
                                        <ListTable parentName={tab.parent} componentName={tab.component}
                                                   actionsExtraQuery={tab.createExtraQuery}
                                                   extraQuery={tab.extraQuery} pageSize={10}
                                                   title={tab.title}
                                                   hideColumns={tab.hideColumns}
                                                   actionsSize={"small"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Fragment>
        );
    }
}

export default ComponentTabs;
