import React, {Fragment} from 'react';
import NumberFormat from 'react-number-format';

class InvoiceTotalWidget extends React.Component {

    render() {
        const {formData, onDataUpdate} = this.props;

        const numberWidget = (total, text, showSeparator, className) => {
            return (
                <div className="media chart-university text-right">
                    <div className="media-body">
                        <h3 className={"mb-0 " + (className ? className : "")}>
                            <NumberFormat value={parseFloat(total)} displayType={'text'}
                                          thousandSeparator={','}
                                          allowNegative decimalScale={2}
                                          fixedDecimalScale/>
                        </h3>
                        <p>{text}</p>
                        {showSeparator && <hr/>}
                    </div>
                </div>
            );
        };

        const currencyName = formData.currency ? formData.currency.name : '';
        return (
            <Fragment>
                <div className={"card"}>
                    <div className={"card-body"}>

                        {numberWidget(formData.totalWVat, `Total with vat ${currencyName}`, true, 'txt-primary')}

                        {numberWidget(formData.totalVat, `Total vat ${currencyName}`, true)}

                        {numberWidget(formData.totalWDiscount, `Total with discount ${currencyName}`, true, 'txt-primary')}

                        {numberWidget(formData.totalDiscount, `Total discount ${currencyName}`, true)}

                        {numberWidget(formData.total, `Total ${currencyName}`)}


                    </div>
                </div>
            </Fragment>
        );
    }
}

export default InvoiceTotalWidget;
