import * as Icon from 'react-feather';
import {File, Headphones} from 'react-feather';
import store from '../store/index';
import Api from "../service/api";

export const MENUITEMS = [
    {
        title: 'Dashboard', icon: Icon['Airplay'], type: 'sub', badgeType: 'primary', active: false, children: [
            {path: '/dashboard/default', title: 'Default', type: 'link'},
            {path: '/dashboard/ecommerce', title: 'E-Commerce', type: 'link'},
            {path: '/dashboard/university', title: 'University', type: 'link'},
            {path: '/dashboard/crypto', title: 'Crypto', type: 'link'},
            {path: '/dashboard/project', title: 'Project', type: 'link'}
        ]
    },
    {
        title: 'Support Ticket', icon: Headphones, type: 'link', path: '/support-ticket/supportTicket', active: false
    },
    {
        path: '/sample/samplepage', title: 'Sample Page', icon: File, type: 'link', active: false
    },
];

class Menu {
    constructor() {
        this.fetchItems();
    }

    fetchItems() {
        // let components = window.location.href.split('/');
        // let parentComponent, component = '';
        // if (components.length >= 6) {
        //     parentComponent = components[4];
        //     component = components[5];
        // }
        // let url = Api.getBackendAddress() + 'app/ui/menu/get?parentComponent=' + parentComponent + '&component=' + component;
        // fetch(url).then(res => res.json()).then(res => {
        //     let menuItems = res.menu;
        //     menuItems.map(item => {
        //         item.icon = Icon[item.iconName]
        //     });
        //     store.dispatch({type: 'SET_MENU', payload: menuItems, logo: res.logo});
        // })
    }
}

export const menuInstance = new Menu();
